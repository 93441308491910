import React, { Fragment, PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import $ from "jquery";
import { IS_AUTHENTICATED, AJAX_PUBLIC_REQUEST, AJAX_REQUEST, REMOVE_STORAGE, USER, GET_COOKIE, DELETE_COOKIE, GET_STORAGE, MEAL_COUNT, ITEM_COUNT, PUBLIC_URL, createKlaviyoIdentify } from "../../../Constants/AppConstants";
import PropTypes from "prop-types";
import classnames from 'classnames';
import history from "../../../history";
import Parser from 'html-react-parser';
import { connect } from 'react-redux';
import { logout } from '../../../Store/actions/loginActions';
import { userLoginRequest } from '../../../Store/actions/loginActions';
import { Row, Col } from 'react-bootstrap';

import colectEmailValidate from '../../../Validations/collectEmailValidate'
import validateLogin from '../../../Validations/Login';

import PasswordReset from '../../Auth/PasswordReset'


class CheckoutLogin extends PureComponent {
    constructor(props) {
        super(props);

        // Check Tax
        let settings = '';
        if (GET_STORAGE('settings')) {
            settings = JSON.parse(GET_STORAGE('settings'));
        }

        this.state = {
            loading: true,
            error: '',
            affiliate_code: GET_COOKIE('af'),
            af_created_date: GET_COOKIE('af_created_date'),

            // Validation
            errors: {},
            isValid: false,
            isLoading: false,
            isFormValid: true,
            server_message: '',

            //User Email collect
            user_email: '',
            submit_email_loading: false,
            found_user_by_email: false,
            user_login: "",
            password: '',
            showLostPassword: false,
            isEmailDisabled: false
        }
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    changeUserEmail = (e) => {
        e.preventDefault()

        this.setState({
            submit_email_loading: false,
            found_user_by_email: false,
            user_email: '',
            user_login: '',
            isEmailDisabled: false
        });

        this.props.checkoutEmailCheckHandler({ type: 'success', message: '', action: 'change-email' });
    }

    onSubmitCheckEmail = (e) => {
        e.preventDefault();
        this.setState({ errors: {}, isLoading: true, submit_email_loading: true });

        const val_return = colectEmailValidate({ user_email: this.state.user_email });
        this.setState(val_return);

        if (val_return.isValid) {
            AJAX_PUBLIC_REQUEST("POST", "user/haveExists", { email: this.state.user_email }).then(results => {
                if (parseInt(results.response.code) === 1000) {

                    let responseData = results.response.data
                    if (responseData.have_exists === 1) {
                        //For Email Found
                        this.setState({
                            isLoading: false,
                            submit_email_loading: false,
                            found_user_by_email: true,
                            user_login: this.state.user_email,
                            isEmailDisabled: true
                        });

                        this.props.checkoutEmailCheckHandler({ type: 'success', message: 'An user registered with this email, please enter password to login', action: 'user-found' });
                    }
                    else {
                        //For Email no Found
                        this.setState({
                            isLoading: false,
                            submit_email_loading: false,
                            found_user_by_email: false,
                            isEmailDisabled: true
                        });

                        // if (this.state.affiliate_code === '') {

                        //     this.setState({
                        //         isEmailDisabled: false
                        //     });

                        //     this.props.checkoutEmailCheckHandler({
                        //         type: 'error',
                        //         message: '<strong>Sorry</strong> You are not allowed to make any purchase on this site. To be able purchase, you must use the referral link from any of our affiliate.',
                        //     });

                        // }
                        // else {
                            this.props.checkoutEmailCheckHandler({ type: 'success', action: 'no-user-found', message: '', user_email: this.state.user_email });
                        // }
                    }

                } else {
                    this.setState({
                        isloading: false,
                        submit_email_loading: false,
                    })
                    this.props.checkoutEmailCheckHandler({ type: 'error', message: results.response.message });
                }
            });


        }
        else {
            this.setState({
                submit_email_loading: false,
                isLoading: false
            });
            this.props.checkoutEmailCheckHandler({ type: 'error', message: 'Please fix the following error(s).' });
        }
    }

    onSubmitLogin = (e) => {

        e.preventDefault();
        this.setState({ errors: {}, user_login: this.state.user_email });

        const val_return = validateLogin(this.state);
        this.setState(val_return);
        if (val_return.isValid) {
            this.setState({ errors: {}, isLoading: true });
            let login_data = {
                user_login: this.state.user_login,
                password: this.state.password,
                affiliate_code: this.state.affiliate_code,
                affiliate_code_created_date: this.state.af_created_date
            };
            this.props.userLoginRequest(login_data).then(results => {
                if (results.response.code === 1000) {
                    if (Object.values(results.response.data.roles).includes('customer')) {

                        let settings = null;
                        if (GET_STORAGE("settings")) {
                            settings = JSON.parse(GET_STORAGE("settings"));
                        }

                        let meal_menu_active = false;
                        if (settings && settings.meal_menu_public == "yes") {
                            meal_menu_active = true;
                        } else {
                            if (results.response.data.meal_menu_activated) {
                                meal_menu_active = true;
                            }
                        }

                        this.setState({
                            isLoading: false
                        });

                        createKlaviyoIdentify();
                        this.props.checkOutLoginHandler({ type: 'success', message: 'Successfully Loggedin' });

                    } else {
                        this.props.logout();
                        REMOVE_STORAGE(USER);
                        this.setState({
                            // server_message: results.response.message,
                            server_message: "Unauthorized Access",
                            isLoading: false,
                            isFormValid: false
                        });

                        this.props.checkOutLoginHandler({ type: 'error', message: 'Unauthorized Access' });

                    }
                } else {
                    this.setState({
                        server_message: results.response.message,
                        isLoading: false,
                        isFormValid: false
                    });

                    this.props.checkOutLoginHandler({ type: 'error', message: results.response.message });
                }
            }
            );
        }
        else {
            this.setState({
                submit_email_loading: false,
                isLoading: false
            });

            this.props.checkOutLoginHandler({ staus: 'error', message: 'Please fix the following error(s).' });
        }
    }

    lostPassword = (e) => {
        e.preventDefault()
        this.setState({
            showLostPassword: true
        })
        $("#lostPassword").modal({
            backdrop: 'static',
            keyboard: false,
        });
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {

        const { errors, captchaCode } = this.state;
        return (
            <Fragment>
                {
                    this.state.found_user_by_email === false ?
                        <div className='w-100'>
                            <form className="w-100" onSubmit={this.onSubmitCheckEmail}>
                                <Row className='checkout_row checkout_label mb_23'>
                                    <Col md={8}>
                                        <label className={classnames(null, { 'pl_error_label': errors.user_email })} htmlFor="user_email">Email address <span className="required">*</span></label>
                                        {
                                            this.state.isEmailDisabled === true ?
                                                <div className="lost_password">
                                                    <a className='text-underline' href='' name="change-emai" onClick={this.changeUserEmail}> Change Email</a>
                                                </div>
                                                : ''
                                        }
                                        <input disabled={this.state.isEmailDisabled} type="text" className={classnames("form-control height_42", { 'pl_error_input': errors.user_email })} name="user_email" id="user_email" value={this.state.user_email} onChange={this.changeHandler} />
                                    </Col>
                                    <Col md={4} className="d-flex align-items-end">
                                        {
                                            this.state.isEmailDisabled === false ?

                                                <button disabled={this.state.submit_email_loading} type="submit" className="w-100 shop_more take_quiz_btn font_16 text_white fw_ar_reg" >Continue</button>

                                                :
                                                ''
                                        }
                                    </Col>
                                </Row>
                            </form>
                            <div className="text-center">
                                <span className="d-inline-block font_16 text_black choose_text fw_ar_reg">or</span>
                            </div>
                        </div>
                        : ''
                }

                {
                    this.state.found_user_by_email === true ?
                        <div className="w-100">
                            <form className="w-100" onSubmit={this.onSubmitLogin}>
                                <Row className='checkout_row checkout_label mb_23'>
                                    <Col md={12}>
                                        <div className="form-group">
                                            <div className='d-flex justify-content-between'>
                                                <label className={classnames(null, { 'pl_error_label': errors.user_email })} htmlFor="user_email">Email address <span className="required">*</span></label>

                                                <div className="lost_password">
                                                    <a className='text-underline' href='' name="change-emai" onClick={this.changeUserEmail}> Change Email</a>
                                                </div>

                                            </div>
                                            <input type="email" className='form-control height_42' disabled id="user_email" value={this.state.user_email} />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="form-group">
                                            <label className={classnames(null, { 'pl_error_label': errors.password })} htmlFor="password">Password <span className="required">*</span></label>
                                            <input className={classnames("form-control height_42", { 'pl_error_input': errors.password })} type="password" name="password" id="password" value={this.state.password} onChange={this.changeHandler} />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="mt_15">
                                            <button type="submit" disabled={this.state.isLoading} className="w-100 shop_more take_quiz_btn font_16 text_white fw_ar_reg" name="login" value="Login">Login</button>
                                        </div>
                                        <div className="text-right mt_15" >
                                            <a href='' className='font_16 text_black fw_ar_reg text_underline' onClick={this.lostPassword}> Lost your password?</a>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                        : ''
                }

                <div className="modal fade" id="lostPassword" tabIndex="-1" role="dialog" aria-labelledby="lostPassword" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header cus-modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body montserrat">
                                {
                                    this.state.showLostPassword ?
                                        <PasswordReset closeModal={this.state.showLostPassword} />
                                        : ''
                                }

                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }
}

CheckoutLogin.propTypes = {
    userLoginRequest: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated
    }
}

export default connect(mapStateToProps, { userLoginRequest, logout })(CheckoutLogin);