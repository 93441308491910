import React, { Fragment, PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import $ from "jquery";
import { connect } from 'react-redux';
import { CRYPTO_ENCRYPTION, AJAX_REQUEST, AJAX_PUBLIC_REQUEST, IS_AUTHENTICATED, AJAX_ACCOUNT_KIT_REQUEST, CURRENCY_FORMAT, CART_TOTAL_CURRENCY_FORMAT, COUNT_SUBSCRIPTION, SET_STORAGE, GET_STORAGE, REMOVE_STORAGE, CHECK_STORAGE, DESTROY_CART, ITEM_COUNT, MEAL_COUNT, NEXT_MONTH, NEXT_WEEK, MEAL_SUB_TOTAL, MEAL_TOTAL, CART_SUB_TOTAL, RECURRING_CART_SUB_TOTAL, COUPON_TOTAL, CanadaRestrictedProduct, ORDER_RECEIVED, GET_COOKIE, DELETE_COOKIE, klaviyoStartedCheckout, createKlaviyoIdentify, BUNDLE_COUNT, BUNDLE_SUB_TOTAL, BUNDLE_OFFER_NEW, BUNDLE_CART_COUNT } from "../../Constants/AppConstants";
import PropTypes from "prop-types";
import classnames from 'classnames';
import history from "../../history";
import Parser from 'html-react-parser';

import AccountKit from 'react-facebook-account-kit';

import { checkoutRequest } from '../../Store/actions/checkoutActions';
import checkoutValidate from '../../Validations/CheckoutValidate';
import AlertWrapper from '../Common/AlertWrapper';
import AlertWrapperSuccess from '../Common/AlertWrapperSuccess';
import CartMonths from '../Common/CartMonths';
import CartYears from '../Common/CartYears';
import PreventCheckoutModal from './PreventCheckoutModal';
// import ShippingSchedule from './ShippingSchedule';
import SezzleIcon from '../../Assets/images/public_images/sezzle_icon.svg'
import CreditCard from '../../Assets/images/public_images/credit_card_img.svg'
import { Row, Col, Nav } from 'react-bootstrap';
import LeftArrow from '../../Assets/images/refer_images/left_arrow.svg'
import CheckoutLogo from '../../Assets/images/refer_images/checkout_page_logo.svg'
import CartSummaryIcon from '../../Assets/images/refer_images/cart_summary_icon.svg'

import BackArrow from '../../Assets/images/refer_images/back_arrow.svg'
import { setUserLogin } from '../../Store/actions/loginActions';
import PayPalIcon from '../../Assets/images/refer_images/paypal_img.svg'

class CheckoutPayment extends PureComponent {
    constructor(props) {
        super(props);

        // Check Tax
        let settings = '';
        if (GET_STORAGE('settings')) {
            settings = JSON.parse(GET_STORAGE('settings'));
        }

        let billing_data = '';
        if (JSON.parse(GET_STORAGE('billing_data'))) {
            billing_data = JSON.parse(GET_STORAGE('billing_data'));
        }

        this.state = {
            loading: true,
            error: '',
            stockoutErrorMessage: '',
            terms_of_use: settings ? (settings.internal_pages ? settings.internal_pages.terms_of_use : '/') : "/",
            privacy_policy: settings ? (settings.internal_pages ? settings.internal_pages.privacy_policy : '/') : "/",

            payment_data: billing_data,
            billing_1_name: billing_data ? (billing_data.billing_first_name ? billing_data.billing_first_name : '') : "",
            billing_2_name: billing_data ? (billing_data.billing_last_name ? billing_data.billing_last_name : '') : "",
            billing_organization: billing_data ? (billing_data.billing_company ? billing_data.billing_company : '') : "",
            billing_nation: billing_data ? (billing_data.billing_country ? billing_data.billing_country : '') : "",
            billing_email_id: billing_data ? (billing_data.billing_email ? billing_data.billing_email : '') : "",
            billing_address_first: billing_data ? (billing_data.billing_address_1 ? billing_data.billing_address_1 : '') : "",
            billing_address_second: billing_data ? (billing_data.billing_address_2 ? billing_data.billing_address_2 : '') : "",
            billing_city_1: billing_data ? (billing_data.billing_city ? billing_data.billing_city : '') : "",
            billing_state_1: billing_data ? (billing_data.billing_state ? billing_data.billing_state : '') : "",
            billing_postcode_1: billing_data ? (billing_data.billing_postcode ? billing_data.billing_postcode : '') : "",
            billing_mobile: billing_data ? (billing_data.billing_phone ? billing_data.billing_phone : '') : "",
            ship_to_different_addr: billing_data ? (billing_data.ship_to_different_address ? billing_data.ship_to_different_address : '') : "",
            shipping_1_name: billing_data ? (billing_data.shipping_first_name ? billing_data.shipping_first_name : '') : "",
            shipping_2_name: billing_data ? (billing_data.shipping_last_name ? billing_data.shipping_last_name : '') : "",
            shipping_organization: billing_data ? (billing_data.shipping_company ? billing_data.shipping_company : '') : "",
            shipping_nation: billing_data ? (billing_data.shipping_country ? billing_data.shipping_country : '') : "",
            shipping_address_first: billing_data ? (billing_data.shipping_address_1 ? billing_data.shipping_address_1 : '') : "",
            shipping_address_second: billing_data ? (billing_data.shipping_address_2 ? billing_data.shipping_address_2 : '') : "",
            shipping_city_2: billing_data ? (billing_data.shipping_city ? billing_data.shipping_city : '') : "",
            shipping_state_2: billing_data ? (billing_data.shipping_state ? billing_data.shipping_state : '') : "",
            shipping_postcode_2: billing_data ? (billing_data.shipping_postcode ? billing_data.shipping_postcode : '') : "",
            agree_to_receive_text_1: billing_data ? (billing_data.agree_to_receive_text ? billing_data.agree_to_receive_text : '') : "",
            continue_without_verify_1: billing_data ? (billing_data.continue_without_verify ? billing_data.continue_without_verify : '') : "",
            send_promotion_update_1: billing_data ? (billing_data.send_promotion_update ? billing_data.send_promotion_update : '') : "",
            order_note_1: billing_data ? (billing_data.order_note ? billing_data.order_note : '') : "",
            shipping_method_id_1: billing_data ? (billing_data.shipping_method_id ? billing_data.shipping_method_id : '') : "",
            recurring_shipping_method_id_1: billing_data ? (billing_data.recurring_shipping_method_id ? billing_data.recurring_shipping_method_id : '') : "",
            tax_amount_1: billing_data ? (billing_data.tax_amount ? billing_data.tax_amount : '') : "",
            subscription_tax_amount_1: billing_data ? (billing_data.subscription_tax_amount ? billing_data.subscription_tax_amount : '') : "",
            meal_tax_amount_1: billing_data ? (billing_data.meal_tax_amount ? billing_data.meal_tax_amount : '') : "",
            subscription_meal_tax_amount_1: billing_data ? (billing_data.subscription_meal_tax_amount ? billing_data.subscription_meal_tax_amount : '') : "",
            tax_info_1: billing_data ? (billing_data.tax_info ? billing_data.tax_info : '') : "",
            subscription_tax_info_1: billing_data ? (billing_data.subscription_tax_info ? billing_data.subscription_tax_info : '') : "",
            meal_tax_info_1: billing_data ? (billing_data.meal_tax_info ? billing_data.meal_tax_info : '') : "",
            subscription_meal_tax_info_1: billing_data ? (billing_data.subscription_meal_tax_info ? billing_data.subscription_meal_tax_info : '') : "",
            cart_items_1: billing_data ? (billing_data.cart_items ? billing_data.cart_items : '') : "",
            meals_1: billing_data ? (billing_data.meals ? billing_data.meals : '') : "",
            cart_coupons_1: billing_data ? (billing_data.cart_coupons ? billing_data.cart_coupons : '') : "",
            user_email_1: billing_data ? (billing_data.user_email ? billing_data.user_email : '') : "",

            // How did you hear about us?
            isEnableHowYouKnow: (settings.is_enable_how_you_know == 'yes') ? "yes" : "no",
            isKnowOthers: false,
            howYouKnowOptions: [],
            how_you_know: "",
            how_you_know_others: "",

            // Facebook mobile verification
            phone_verification_on_checkout: settings ? settings.phone_verification_on_checkout : '',
            facebook_app_id: settings ? settings.facebook_app_id : '',
            account_kit_app_secret: settings ? settings.account_kit_app_secret : '',
            account_kit_api_version: settings ? settings.account_kit_api_version : '',

            cartShippingCost: 0,
            recurringCartShippingCost: 0,
            cartTotal: 0,
            mealShippingCostStatus: true,

            taxStatus: settings ? settings.tax_status : 0,
            tax_amount: 0,
            tax_info: '',
            subscription_tax_amount: 0,
            subscription_tax_info: '',
            meal_tax_amount: 0,
            meal_tax_info: '',
            subscription_meal_tax_amount: 0,
            subscription_meal_tax_info: '',

            items: [],
            bundleItems:[],
            meals: [],
            coupons: [],
            shippingMethods: [],
            countryList: [],
            billingStateList: [],
            shippingStateList: [],
            freeShipping: 0,
            taxFree: 0,
            show_coupon: false,
            coupon_code_button_loading: false,
            coupon_code: "",
            success_alert_wrapper_show: false,
            place_order_loading: false,

            // Billing Details
            billing_first_name: '',
            billing_last_name: '',
            billing_company: '',
            billing_country: '',
            billing_address_1: '',
            billing_address_2: '',
            billing_city: '',
            billing_state: '',
            billing_postcode: '',
            billing_phone: '',
            billing_email: '',

            // Address verify
            addressVerifyCode: false,
            continue_without_verify: 0,
            agree_to_receive_text: 'yes',
            send_promotion_update: 'no',

            // Different Shipping Address
            shippingAreaVisible: false,
            ship_to_different_address: 'no',

            shipping_first_name: '',
            shipping_last_name: '',
            shipping_company: '',
            shipping_country: '',
            shipping_address_1: '',
            shipping_address_2: '',
            shipping_city: '',
            shipping_state: '',
            shipping_postcode: '',

            order_note: '',

            // Payment Method
            payment_method: (settings.payment_method) ? settings.payment_method : '',
            name_on_card: '',
            card_number: '',
            expire_month: '',
            expire_year: '',
            cvv: '',
            agreeTermsPolicy: false,

            // Validation
            errors: {},
            isValid: false,
            isLoading: false,
            isFormValid: true,
            server_message: '',
            isCouponReapply: 0,

            isFormDisabled: IS_AUTHENTICATED() ? false : false,
            isAuthenticated: IS_AUTHENTICATED(),
            user_email: '',
            hasSubscriptionYes: false,
            hasSubscriptionNo: false,
            subscription: GET_STORAGE("is_subscription"),
            subscription_detail: JSON.parse(GET_STORAGE("CustomSubscriptionDetail"))
        }
        document.title = "CheckOut-Prestige Labs";

    }

    changeHandler = (e) => {

        if (e.target.name === 'name_on_card') {
            const name_on_card = e.target.value;
            this.setState({
                [e.target.name]: name_on_card.toUpperCase()
            })
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
        
    }

    // How did you hear about us?
    howKnowChangeHandler = (e) => {
        if (e.target.value == 'others') {
            this.setState({
                isKnowOthers: true,
                [e.target.name]: e.target.value
            })
        } else {
            this.setState({
                isKnowOthers: false,
                [e.target.name]: e.target.value,
                how_you_know_others: ""
            })
        }
    }

    changeHandlerWithCallTax = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.getTax(this.state.ship_to_different_address);
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        document.body.classList.remove('home_page');
        //this.reApplyCoupon();
        this.getCart();
        this.getAllShippingMethods();
        this.getCountryList();
        if (this.state.isAuthenticated) {
            this.getBillingAddress();
            this.getShippingAddress();
            setTimeout(function () {
                klaviyoStartedCheckout();
                this.addMemberToKlaviyoSubscriber();
            }.bind(this), 5000)
        }
        if (this.state.isEnableHowYouKnow == 'yes') {
            this.getHowYouKnowOptions();
        }

        const cartItems = this.state.payment_data.cart_items;
        cartItems.forEach(item => {
            if (item.subscription === "yes") {
                this.state.hasSubscriptionYes = true;
            } 
            if (item.subscription === "no") {
                this.state.hasSubscriptionNo = true;
            }
        });
        if((BUNDLE_CART_COUNT() != 0 ) && GET_STORAGE("is_subscription")){
            this.state.hasSubscriptionYes = (GET_STORAGE("is_subscription") == "yes") ? true : false;
        }
        let cartMethodId = GET_STORAGE("cartID");
        SET_STORAGE("cartMethodId", cartMethodId);
    }

    getHowYouKnowOptions = () => {
        AJAX_REQUEST("POST", "user/getHowYouKnowOptions", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({ howYouKnowOptions: results.response.data })
            } else {
                this.setState({
                    howYouKnowOptions: [],
                    error: results.response.message
                })
            }
        });
    }

    // Get and calculate tax if applicable
    getTax = (checkStatus = 'no') => {
        this.setState({
            tax_amount: 0,
            tax_info: '',
            subscription_tax_amount: 0,
            subscription_tax_info: '',
            meal_tax_amount: 0,
            meal_tax_info: '',
            subscription_meal_tax_amount: 0,
            subscription_meal_tax_info: '',
        })

        if (this.state.taxStatus == 1) {
            let address_1 = '';
            let postcode = '';
            let city = '';
            let state = '';
            let country = '';
            if (checkStatus == "checked") {
                // address_1 = document.getElementById("shipping_address_1").value;
                // postcode = document.getElementById("shipping_postcode").value;
                // city = document.getElementById("shipping_city").value;
                // state = document.getElementById("shipping_state").value;
                // country = document.getElementById("shipping_country").value;
                address_1 = this.state.shipping_address_first;
                postcode = this.state.shipping_postcode_2;
                city = this.state.shipping_city_2;
                state = this.state.shipping_state_2;
                country = this.state.shipping_nation;
            } else {
                // address_1 = document.getElementById("billing_address_1").value;
                // postcode = document.getElementById("billing_postcode").value;
                // city = document.getElementById("billing_city").value;
                // state = document.getElementById("billing_state").value;
                // country = document.getElementById("billing_country").value;
                address_1 = this.state.billing_address_first;
                postcode = this.state.billing_postcode_1;
                city = this.state.billing_city_1;
                state = this.state.billing_state_1;
                country = this.state.billing_nation;
            }

            if (address_1 != '' && postcode != '' && city != '' && state != '' && country != '') {

                let taxData = {
                    address_1: address_1,
                    postcode: postcode,
                    city: city,
                    state: state,
                    country: country,
                    shipping_method_id: this.state.shipping_method_id,
                    recurring_shipping_method_id: this.state.recurring_shipping_method_id,
                    cart_items: JSON.parse(GET_STORAGE("cart")) ? JSON.parse(GET_STORAGE("cart")) : JSON.parse(GET_STORAGE("BundleCart")), // set shoping carts
                    meals: JSON.parse(GET_STORAGE("meals")), // set meals
                    cart_coupons: JSON.parse(GET_STORAGE("coupon")),
                }
                //TaxJar ping adjustment
                if(this.state.isCouponReapply == 1) {
                    AJAX_PUBLIC_REQUEST("POST", "order/getTax", taxData).then(results => {
                        if (parseInt(results.response.code) === 1000 && results.response.data != '') {
                            let data = results.response.data;
                            this.setState({
                                tax_amount: (data.tax_amount) ? data.tax_amount : 0,
                                tax_info: data.tax_info,
                                subscription_tax_amount: (data.subscription_tax_amount) ? data.subscription_tax_amount : 0,
                                subscription_tax_info: data.subscription_tax_info,
                                meal_tax_amount: (data.meal_tax_amount) ? data.meal_tax_amount : 0,
                                meal_tax_info: data.meal_tax_info,
                                subscription_meal_tax_amount: (data.subscription_meal_tax_amount) ? data.subscription_meal_tax_amount : 0,
                                subscription_meal_tax_info: data.subscription_meal_tax_info,
                            });
                        }
                    });
                } else {
                    let data = JSON.parse(GET_STORAGE("taxInfo"));
                    this.setState({
                        tax_amount: (data.tax_amount) ? data.tax_amount : 0,
                        tax_info: data.tax_info,
                        subscription_tax_amount: (data.subscription_tax_amount) ? data.subscription_tax_amount : 0,
                        subscription_tax_info: data.subscription_tax_info,
                        meal_tax_amount: (data.meal_tax_amount) ? data.meal_tax_amount : 0,
                        meal_tax_info: data.meal_tax_info,
                        subscription_meal_tax_amount: (data.subscription_meal_tax_amount) ? data.subscription_meal_tax_amount : 0,
                        subscription_meal_tax_info: data.subscription_meal_tax_info,
                    }); 
                }
            }
        }
    }

    timeOut = (timedata) => {
        setTimeout(function () {
            this.setState({
                success_alert_wrapper_show: false,
            });
        }.bind(this), timedata);
    }

    getCart = () => {
        if ((ITEM_COUNT() === 0) && (MEAL_COUNT() === 0) && (BUNDLE_COUNT() === 0)) {
            history.push("/");
        }
        CHECK_STORAGE();
        if (GET_STORAGE('cart')) {
            this.setState({ items: JSON.parse(GET_STORAGE('cart')) });
        } else {
            this.setState({ items: [] });
        }
        if (GET_STORAGE('meals')) {
            let meals = JSON.parse(GET_STORAGE('meals'));
            if (meals.hasOwnProperty('items') && meals.items.length > 0) {
                this.setState({ meals: meals });
            } else {
                REMOVE_STORAGE('meals');
                this.setState({ meals: [] });
            }
        } else {
            this.setState({ meals: [] });
        }
        if (GET_STORAGE('coupon')) {
            this.setState({ coupons: JSON.parse(GET_STORAGE("coupon")) });
        } else {
            this.setState({ coupons: [] });
        }
        if (GET_STORAGE('BundleCart')) {
            this.setState({ bundleItems: JSON.parse(GET_STORAGE('BundleCart')) });
        } else {
            this.setState({ bundleItems: [] });
        }
    }

    getAllShippingMethods = () => {
        AJAX_PUBLIC_REQUEST("POST", "order/getShippingMethodList", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                let methods = results.response.data;
                let method = methods.filter(c => c.id === GET_STORAGE("cartMethodId"));
                let method2 = methods.filter(c => c.id === GET_STORAGE("recurringCartMethodId"));

                if (this.state.shipping_method_id) {
                    if (this.state.shipping_method_id != GET_STORAGE("cartMethodId")) {
                        SET_STORAGE("cartMethodId", methods[0].id);
                    }
                }

                this.setState({
                    shippingMethods: methods,
                    shipping_method_id: GET_STORAGE("cartMethodId"),
                    // cartShippingCost: (this.state.shipping_method_id != GET_STORAGE("cartMethodId")) ? method[0].cost : methods[0].cost,
                    cartShippingCost: (GET_STORAGE("cartMethodId")) ? method[0].cost : methods[0].cost,
                });
                if (method2) {
                    this.setState({
                        recurring_shipping_method_id: GET_STORAGE("recurringCartMethodId"),
                        recurringCartShippingCost: method2[0].cost,
                    });
                }
            } else {
                this.setState({
                    error: results.response.message,
                })
            }
        });
    }

    getCountryList = () => {
        AJAX_PUBLIC_REQUEST("POST", "user/getCountry", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    loading: false,
                    countryList: results.response.data
                });
                this.getTax();
            } else {
                this.setState({
                    loading: false,
                    error: results.response.message,
                })
            }
        });
    }

    getBillingAddress = () => {
        AJAX_REQUEST("POST", "user/billingDetails", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    loading: false,
                    billing_first_name: results.response.data.billing_first_name,
                    billing_last_name: results.response.data.billing_last_name,
                    billing_company: results.response.data.billing_company,
                    billing_address_1: results.response.data.billing_address_1,
                    billing_address_2: results.response.data.billing_address_2,
                    billing_city: results.response.data.billing_city,
                    billing_postcode: results.response.data.billing_postcode,
                    billing_country: results.response.data.billing_country_id,
                    billing_state: results.response.data.billing_state,
                    billing_phone: results.response.data.billing_phone,
                    billing_email: results.response.data.billing_email
                });
                if (parseInt(results.response.data.billing_country_id) == 38 && CanadaRestrictedProduct()) {
                    $("#canadaRestrictedProductModal").modal({
                        backdrop: 'static',
                        keyboard: false,
                    });
                    $("#canadaRestrictedProductModalBody").html(CanadaRestrictedProduct());
                    this.setState({
                        billing_country: '',
                        billing_state: '',
                        billingStateList: [],
                    })
                }
                this.getBillingStateList(this.state.billing_country);
            } else {
                this.setState({
                    loading: false,
                    error_meg: results.response.message,
                })
            }
        });
    }

    onChangeBillingCountry = (e) => {
        let countryId = e.target.value;
        if (!document.getElementById("ship_to_different_address").checked && parseInt(countryId) == 38 && CanadaRestrictedProduct()) {
            $("#canadaRestrictedProductModal").modal({
                backdrop: 'static',
                keyboard: false,
            });
            $("#canadaRestrictedProductModalBody").html(CanadaRestrictedProduct());
        } else {
            this.setState({ [e.target.name]: countryId })
            this.getBillingStateList(countryId);
        }
    }
    getBillingStateList = (countryId) => {
        let data = { country_id: countryId };
        AJAX_REQUEST("POST", "user/getState", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({ billingStateList: results.response.data });
            } else {
                this.setState({ billingStateList: [] })
            }
            this.getTax();
        });
    }

    getShippingAddress = () => {
        AJAX_REQUEST("POST", "user/shippingDetails", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    loading: false,
                    shipping_first_name: results.response.data.shipping_first_name,
                    shipping_last_name: results.response.data.shipping_last_name,
                    shipping_company: results.response.data.shipping_company,
                    shipping_address_1: results.response.data.shipping_address_1,
                    shipping_address_2: results.response.data.shipping_address_2,
                    shipping_city: results.response.data.shipping_city,
                    shipping_postcode: results.response.data.shipping_postcode,
                    shipping_country: results.response.data.shipping_country_id,
                    shipping_state: results.response.data.shipping_state,
                });
                this.getShippingStateList(this.state.shipping_country);
            } else {
                this.setState({
                    loading: false,
                    error_meg: results.response.message,
                })
            }
        });
    }
    onChangeShippingCountry = (e) => {
        let countryId = e.target.value;
        if (document.getElementById("ship_to_different_address").checked && parseInt(countryId) == 38 && CanadaRestrictedProduct()) {
            $("#canadaRestrictedProductModal").modal({
                backdrop: 'static',
                keyboard: false,
            });
            $("#canadaRestrictedProductModalBody").html(CanadaRestrictedProduct());
        } else {
            this.setState({ [e.target.name]: countryId })
            this.getShippingStateList(countryId);
        }
    }
    getShippingStateList = (countryId) => {
        let data = { country_id: countryId };
        AJAX_REQUEST("POST", "user/getState", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({ shippingStateList: results.response.data });
            } else {
                this.setState({ shippingStateList: [] })
            }
            this.getTax();
        });
    }

    changeCartShippingMethod = (e) => {
        this.setState({
            shipping_method_id: e.target.getAttribute('cid'),
            cartShippingCost: e.target.getAttribute('price'),
        })
        SET_STORAGE("cartMethodId", e.target.getAttribute('cid'))
    }

    changeRecurringCartShippingMethod = (e) => {
        this.setState({
            recurring_shipping_method_id: e.target.getAttribute('rid'),
            recurringCartShippingCost: e.target.getAttribute('price'),
        })
        SET_STORAGE("recurringCartMethodId", e.target.getAttribute('rid'))
    }

    continueWithoutVerify = (e) => {
        if (document.getElementById("continue_without_verify").checked) {
            this.setState({ continue_without_verify: 1 });
        } else {
            this.setState({ continue_without_verify: 0 });
        }
    }

    isDifferentShipping = (e) => {
        if (document.getElementById("ship_to_different_address").checked) {
            this.setState({
                ship_to_different_address: 'checked',
                shippingAreaVisible: true,
            });
            if ((parseInt(this.state.shipping_country) == 38) && CanadaRestrictedProduct()) {
                this.setState({
                    shipping_country: '',
                    shipping_state: '',
                    shippingStateList: [],
                })
            }
            this.getTax("checked");
        } else {
            this.setState({
                ship_to_different_address: 'no',
                shippingAreaVisible: false,
            });
            if ((parseInt(this.state.billing_country) == 38) && CanadaRestrictedProduct()) {
                this.setState({
                    billing_country: '',
                    billing_state: '',
                    billingStateList: [],
                })
            }
            this.getTax();
        }
    }
    agreeTermsPolicy = (e) => {
        if (document.getElementById("agreeTermsPolicy").checked) {
            this.setState({ agreeTermsPolicy: true });
        } else {
            this.setState({ agreeTermsPolicy: false });
        }
    }
    sendPromotionUpdate = (e) => {
        if (document.getElementById("send_promotion_update").checked) {
            this.setState({ send_promotion_update: "yes" });
        } else {
            this.setState({ send_promotion_update: "no" });
        }
    }

    // onSubmitHandler = (e) => {
    //     e.preventDefault();

    //     this.setState({ errors: {}, isLoading: true, place_order_loading: true });

    //     let cartData = {
    //         // Billing Details
    //         billing_first_name: this.state.billing_first_name,
    //         billing_last_name: this.state.billing_last_name,
    //         billing_company: this.state.billing_company,
    //         billing_country: this.state.billing_country,
    //         billing_address_1: this.state.billing_address_1,
    //         billing_address_2: this.state.billing_address_2,
    //         billing_city: this.state.billing_city,
    //         billing_state: this.state.billing_state,
    //         billing_postcode: this.state.billing_postcode,
    //         billing_phone: this.state.billing_phone,
    //         billing_email: this.state.billing_email,
    //         // Different Shipping Address
    //         ship_to_different_address: this.state.ship_to_different_address,
    //         shipping_first_name: this.state.shipping_first_name,
    //         shipping_last_name: this.state.shipping_last_name,
    //         shipping_company: this.state.shipping_company,
    //         shipping_country: this.state.shipping_country,
    //         shipping_address_1: this.state.shipping_address_1,
    //         shipping_address_2: this.state.shipping_address_2,
    //         shipping_city: this.state.shipping_city,
    //         shipping_state: this.state.shipping_state,
    //         shipping_postcode: this.state.shipping_postcode,
    //         // Additional Information
    //         agree_to_receive_text: this.state.agree_to_receive_text,
    //         continue_without_verify: this.state.continue_without_verify,
    //         send_promotion_update: this.state.send_promotion_update,
    //         order_note: this.state.order_note,

    //         how_you_know: this.state.how_you_know,
    //         how_you_know_others: this.state.how_you_know_others,

    //         // Payment Method
    //         payment_method: document.querySelector(".select_payment:checked").value,

    //         name_on_card: this.state.name_on_card,
    //         expire_month: this.state.expire_month,
    //         expire_year: this.state.expire_year,
    //         card_number: CRYPTO_ENCRYPTION(this.state.card_number),
    //         cvv: CRYPTO_ENCRYPTION(this.state.cvv),
    //         // Cart Information
    //         shipping_method_id: this.state.shipping_method_id,
    //         recurring_shipping_method_id: this.state.recurring_shipping_method_id,
    //         tax_amount: this.state.tax_amount,
    //         subscription_tax_amount: this.state.subscription_tax_amount,
    //         meal_tax_amount: this.state.meal_tax_amount,
    //         subscription_meal_tax_amount: this.state.subscription_meal_tax_amount,
    //         tax_info: this.state.tax_info,
    //         subscription_tax_info: this.state.subscription_tax_info,
    //         meal_tax_info: this.state.meal_tax_info,
    //         subscription_meal_tax_info: this.state.subscription_meal_tax_info,
    //         cart_items: JSON.parse(GET_STORAGE("cart")),
    //         meals: JSON.parse(GET_STORAGE("meals")),
    //         cart_coupons: JSON.parse(GET_STORAGE("coupon")),
    //     }

    //     const val_return = checkoutValidate(cartData);
    //     this.setState(val_return);

    //     if (!document.getElementById("ship_to_different_address").checked) {
    //         cartData.shipping_first_name = this.state.billing_first_name;
    //         cartData.shipping_last_name = this.state.billing_last_name;
    //         cartData.shipping_company = this.state.billing_company;
    //         cartData.shipping_country = this.state.billing_country;
    //         cartData.shipping_address_1 = this.state.billing_address_1;
    //         cartData.shipping_address_2 = this.state.billing_address_2;
    //         cartData.shipping_city = this.state.billing_city;
    //         cartData.shipping_state = this.state.billing_state;
    //         cartData.shipping_postcode = this.state.billing_postcode;
    //     }

    //     if (val_return.isValid) {

    //         this.props.checkoutRequest(cartData).then(results => {
    //             if (parseInt(results.response.code) === 1000) {
    //                 DESTROY_CART(); // Destroy cart information from localStorage
    //                 this.setState({
    //                     server_message: results.response.message,
    //                     isLoading: false,
    //                     place_order_loading: false,
    //                     isFormValid: false
    //                 });
    //                 document.querySelector("body").scrollIntoView();
    //                 history.push('/order-received/' + results.response.data.order_id);
    //             }
    //             // Check product stockout
    //             else if (parseInt(results.response.code) === 3004) {

    //                 this.setState({
    //                     isLoading: false,
    //                     place_order_loading: false,
    //                     isFormValid: false,
    //                     stockoutErrorMessage: results.response.message,
    //                 });

    //                 $("#stockoutErrorModal").modal({
    //                     backdrop: 'static',
    //                     keyboard: false,
    //                 });

    //             }
    //             else {
    //                 if (parseInt(results.response.code) === 3001) {
    //                     this.setState({ addressVerifyCode: true })
    //                 }
    //                 this.setState({
    //                     server_message: results.response.message,
    //                     isLoading: false,
    //                     place_order_loading: false,
    //                     isFormValid: false
    //                 });
    //                 document.querySelector("body").scrollIntoView();
    //             }
    //         });
    //     } else {
    //         this.setState({
    //             isLoading: false,
    //             place_order_loading: false,
    //             isFormValid: false
    //         });
    //         document.querySelector("body").scrollIntoView();
    //     }
    // }

    onSubmitHandler = (e) => {
        e.preventDefault();

        this.setState({
            success_alert_wrapper_show: false,
            warning_wrapper_show: false,
            errors: {},
            isLoading: true,
            place_order_loading: true,
            server_message: ''
        })

        // if (!this.state.isAuthenticated && this.state.affiliate_code === '') {
        //     this.setState({
        //         server_message: '<strong>Sorry</strong> You are not allowed to make any purchase on this site. To be able purchase, you must use the referral link from any of our affiliate.',
        //         isLoading: false,
        //         place_order_loading: false,
        //         isFormValid: false
        //     });
        //     document.querySelector("body").scrollIntoView();
        //     return;
        // }
        let billing_card_info = this.state.payment_data;
        billing_card_info.cart_coupons = JSON.parse(GET_STORAGE("coupon"));
        billing_card_info.payment_method = this.state.cartTotal > 0 ? document.querySelector(".select_payment:checked").value : '';

        if (billing_card_info.payment_method === 'authorize') {
            this.state.authorize_state = true;
        }
        billing_card_info.name_on_card = this.state.name_on_card;
        billing_card_info.expire_month = Number(this.state.expire_month);
        billing_card_info.expire_year = Number(this.state.expire_year);
        billing_card_info.card_number = CRYPTO_ENCRYPTION(this.state.card_number);
        billing_card_info.cvv = CRYPTO_ENCRYPTION(this.state.cvv);
        billing_card_info.is_custom_bundle = (BUNDLE_CART_COUNT() != 0 ) ? 1 : 0;
        billing_card_info.custom_discount = (BUNDLE_CART_COUNT() != 0 ) ?  BUNDLE_OFFER_NEW() : 0;
        billing_card_info.is_california_warning = (GET_STORAGE("california-warning") == 'yes') ? GET_STORAGE("california-warning"): 'no';
        billing_card_info.is_ny_order = (GET_STORAGE("ny-order") == 'yes') ? GET_STORAGE("ny-order"): 'no';
        billing_card_info.custom_bundle_subscription = (BUNDLE_CART_COUNT() != 0 ) ? GET_STORAGE("is_subscription") : 0;
        const val_return = checkoutValidate(billing_card_info);
        this.setState(val_return);


        if (this.state.isAuthenticated === false && this.state.user_email_1) {
            billing_card_info.user_email = this.state.user_email_1
        }
        if (val_return.isValid) {
            this.props.checkoutRequest(billing_card_info).then(results => {
                if (parseInt(results.response.code) === 1000) {
                    DESTROY_CART(); // Destroy cart information from localStorage
                    this.setState({
                        server_message: results.response.message,
                        isLoading: false,
                        place_order_loading: false,
                        isFormValid: false
                    });
                    document.querySelector("body").scrollIntoView();
                    if (this.state.isAuthenticated === false && this.state.user_email_1) {
                        this.props.setUserLogin(results.response.user_data) //set login data
                        createKlaviyoIdentify();
                    }
                    SET_STORAGE(ORDER_RECEIVED, 'yes'); //Set just receive a order by athore. do not delete that line
                    history.push('/order-received/' + results.response.data.order_id);
                } else if (parseInt(results.response.code) === 200) {
                    if (this.state.isAuthenticated === false && this.state.user_email_1) {
                        createKlaviyoIdentify();
                    }
                    window.location = results.response.data.checkout_url;

                    // Check product stockout
                } 
                else if (parseInt(results.response.code) === 300) {
                    SET_STORAGE('paypal_order_id', results.response.paypal_order_id);
                    SET_STORAGE('paypal_subscription_id', results.response.paypal_subscription_id);
                    SET_STORAGE('order_id', results.response.order_id);
                    SET_STORAGE('id', results.response.id);
                    window.location = results.response.checkoutUrl;
                    // Check product stockout
                }
                else if (parseInt(results.response.code) === 3004) {

                    this.setState({
                        isLoading: false,
                        place_order_loading: false,
                        isFormValid: false,
                        stockoutErrorMessage: results.response.message,
                    });

                    $("#stockoutErrorModal").modal({
                        backdrop: 'static',
                        keyboard: false,
                    });

                }
                else {
                    if (parseInt(results.response.code) === 3001) {
                        this.setState({ addressVerifyCode: true })
                    }

                    this.setState({
                        server_message: results.response.message,
                        isLoading: false,
                        place_order_loading: false,
                        isFormValid: false
                    });
                    document.querySelector("body").scrollIntoView();
                }
            });
        } else {
            this.setState({
                isLoading: false,
                place_order_loading: false,
                server_message: 'Please fix the following error(s).',
                isFormValid: false
            });
            document.querySelector("body").scrollIntoView();
        }
    }

    applyCoupon = (e) => {
        e.preventDefault();
        let couponCode = (this.state.coupon_code).trim();

        this.setState({
            isFormValid: true,
            coupon_loading: true,
            success_alert_wrapper_show_coupon: false
        })

        if (couponCode == '' || couponCode == null) {
            this.setState({
                server_message: "The coupon code field is required.",
                isLoading: false,
                coupon_loading: false,
                isFormValid: false,
            });
        } else {

            let couponExists = false;
            let exCouponList = [];
            if (!GET_STORAGE("coupon")) {
                SET_STORAGE("coupon", JSON.stringify(exCouponList));
            }
            exCouponList = JSON.parse(GET_STORAGE("coupon"));

            if (exCouponList.length > 0) {
                exCouponList.forEach(function (exCoupon, key) {
                    if (exCoupon.coupon_code.toUpperCase() === couponCode.toUpperCase()) {
                        couponExists = true;
                    }
                });
            }

            if (!couponExists) {
                let applyCouponCode = [];
                if (exCouponList.length > 0) {
                    exCouponList.forEach(function (couponData, key) {
                        applyCouponCode.push(couponData.coupon_code);
                    });
                }
                applyCouponCode.push(couponCode);

                let data = {
                    coupon_code: applyCouponCode,
                    cart_items: JSON.parse(GET_STORAGE("cart")),
                    meals: JSON.parse(GET_STORAGE("meals")),
                }

                AJAX_REQUEST("POST", "coupon/applyCoupon", data).then(results => {
                    if (parseInt(results.response.code) === 1000) {

                        this.setState({
                            success_alert_wrapper_show_coupon: false,
                            coupon_loading: false,
                            coupon_code: '',
                            server_message: results.response.message,
                            success_alert_wrapper_show: true
                        })

                        let couponResponse = results.response.data;
                        if (couponResponse || couponResponse != '') {
                            exCouponList = [];
                            couponResponse.forEach(function (couponData, key) {
                                exCouponList.push(couponData);
                            });
                        }

                        SET_STORAGE("coupon", JSON.stringify(exCouponList));
                        this.getAllShippingMethods();
                        this.getCart();
                        this.getTax();
                    } else {
                        this.setState({
                            server_message: results.response.message,
                            isLoading: false,
                            coupon_loading: false,
                            isFormValid: false,
                            error: results.response.message,
                        });
                    }
                    this.timeOut(5000);
                });
            } else {
                this.setState({
                    server_message: "The coupon code already applied.",
                    isLoading: false,
                    coupon_loading: false,
                    isFormValid: false,
                });
            }
        }
    }

    reApplyCoupon = () => {
        if (GET_STORAGE("coupon")) {
            let exCouponList = JSON.parse(GET_STORAGE("coupon"));
            let coupon_code = [];
            exCouponList.forEach(function (couponData, key) {
                coupon_code.push(couponData.coupon_code);
            });
            let data = {
                coupon_code: coupon_code,
                cart_items: JSON.parse(GET_STORAGE("cart")),
                meals: JSON.parse(GET_STORAGE("meals")),
            }
            AJAX_REQUEST("POST", "coupon/applyCoupon", data).then(results => {
                if (parseInt(results.response.code) === 1000) {
                    SET_STORAGE("coupon", JSON.stringify(results.response.data));
                } else if (parseInt(results.response.code) === 4000) {
                    REMOVE_STORAGE('coupon')
                }
                this.setState({
                    isCouponReapply: 1
                });
                this.getAllShippingMethods();
                this.getTax();
                this.getCart();
            });
        }
    }

    deleteCoupon = (e, row_id) => {
        e.preventDefault();
        if (window.confirm("Are you sure want to delete coupon?")) {
            let coupon = JSON.parse(GET_STORAGE('coupon'));
            if (coupon.splice(row_id, 1)) {
                SET_STORAGE("coupon", JSON.stringify(coupon));
                this.setState({
                    freeShipping: 0,
                    mealShippingCostStatus: true,
                    server_message: 'The coupon code successfully removed.',
                    success_alert_wrapper_show_coupon: false,
                    coupon_code: '',
                    success_alert_wrapper_show: true,
                    isCouponReapply: 1
                });
                document.querySelector("body").scrollIntoView();
                this.timeOut(5000);
                this.reApplyCoupon();
                this.getAllShippingMethods();
                this.getCart();
            }
        }
    }

    showCouponAction = (e) => {
        e.preventDefault();
        this.setState({
            show_coupon: this.state.show_coupon ? false : true
        })
    }

    addMemberToKlaviyoSubscriber = () => {
        let data = {
            email: this.props.auth.user.email
        }
        AJAX_REQUEST("POST", "order/addMemberToKlaviyoSubscriber", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                // success
            }
        });
    }

    backToCart = () => {
        $('#stockoutErrorModal').modal('hide');
        history.push('/cart');
    }

    render() {
        var efa_status = false;
        var meal_status = false;
        const { errors, server_message, show_coupon, coupon_code_button_loading, success_alert_wrapper_show, place_order_loading } = this.state;
        const errors_data = server_message;

        let duration_id = 1;
        let duration_text = "Every 1 week";
        if (this.state.meals && this.state.meals.duration_id) {
            duration_id = parseInt(this.state.meals.duration_id);
        }
        if (this.state.meals && this.state.meals.duration_text) {
            duration_text = this.state.meals.duration_text;
        }

        let cartTotal = 0;
        if (this.state.items.length > 0) {
            if (this.state.meals != null && this.state.meals != '' && this.state.meals.items.length > 0 && this.state.mealShippingCostStatus == true) {
                cartTotal = Number(CART_SUB_TOTAL()) + Number(MEAL_TOTAL()) + Number(this.state.cartShippingCost) + Number(this.state.tax_amount) + Number(this.state.meal_tax_amount) - Number(COUPON_TOTAL());
            } else {
                cartTotal = Number(CART_SUB_TOTAL()) + Number(MEAL_SUB_TOTAL()) + Number(this.state.cartShippingCost) + Number(this.state.tax_amount) - Number(COUPON_TOTAL());
            }
        }else if(this.state.bundleItems != null) {
            if(this.state.bundleItems.length > 0){
                cartTotal = Number(BUNDLE_SUB_TOTAL()) + Number(MEAL_SUB_TOTAL()) + Number(this.state.cartShippingCost) + Number(this.state.tax_amount);
            }
        } else {
            if (this.state.meals != null && this.state.meals != '' && this.state.meals.items.length > 0 && this.state.mealShippingCostStatus == true) {
                cartTotal = Number(MEAL_TOTAL()) + Number(this.state.meal_tax_amount) - Number(COUPON_TOTAL());
                meal_status = true;
            } else {
                cartTotal = Number(MEAL_SUB_TOTAL()) + Number(this.state.meal_tax_amount) - Number(COUPON_TOTAL());
            }
        }
        this.setState({ cartTotal: cartTotal })
        this.state.items.map(function (item, key) {
            if (item.cart_product_name == "EFA") {
                efa_status = true;
            }
        });
        return (
            <Fragment>
                {
                    (this.state.loading) ?
                        <div className='loading container full_page_loader'></div>
                        :
                        <Fragment>
                            {
                                ((this.state.shipping_country == "CA" && efa_status) || ((this.state.shipping_state == "AK" || this.state.shipping_state == "HI") && meal_status)) ?
                                    <PreventCheckoutModal productLink="#" /> : ''
                            }
                            <div className="checkout_page">
                                <div className="container-fluid form_input_blk">
                                    <form id="orderForm" className={this.state.isFormDisabled === true ? 'w-100' : ''} onSubmit={this.onSubmitHandler}>
                                        <Row>
                                            <Col md={8} className="checkout_left_blk white_bg">
                                                <AlertWrapper errors_data={errors_data} isFormValid={this.state.isFormValid} />
                                                <AlertWrapperSuccess errors_data={errors_data} success_alert_wrapper_show={success_alert_wrapper_show} />

                                                {
                                                    (!this.state.addressVerifyCode) ? ''
                                                        :
                                                        <Fragment>
                                                            <p className="promotion_and_product_updates addess-verification w-100 m-0">
                                                                <label className="checkbox_label m-0 d-flex align-items-center">
                                                                    <input onClick={this.continueWithoutVerify} id='continue_without_verify' className="" type="checkbox" />
                                                                    <span className="checbox"></span>
                                                                    <span className="d-block font_14 text_black fw_ar_reg pl_10">Continue without verify address?</span>
                                                                </label>
                                                            </p>
                                                        </Fragment>
                                                }
                                                <NavLink to={'/cart'} className="d-flex back_navigation">
                                                    <img src={LeftArrow} />
                                                    <span className="d-block pl_4 font_12 text_black fw_ar_reg">Return to cart</span>
                                                </NavLink>
                                                <div className="text-center mb_31">
                                                    <img src={CheckoutLogo} alt="checkout logo" />
                                                </div>
                                                <ul className="checkout_ul w-100 d-flex align-items-center mb_27">
                                                    <li className='d-flex align-items-center justify-content-center active selected'>
                                                        <span className="d-flex align-items-center justify-content-center white_bg font_11 text_black checkout_round fw_ar_reg">1</span>
                                                        <span className="font_16 d-block text_black fw_ar_reg pl_10">Information</span>
                                                    </li>
                                                    <li className='d-flex align-items-center justify-content-center active selected'>
                                                        <span className="d-flex align-items-center justify-content-center white_bg font_11 text_black checkout_round fw_ar_reg">2</span>
                                                        <span className="font_16 d-block text_black fw_ar_reg pl_10">Shipping</span>
                                                    </li>
                                                    <li className='d-flex align-items-center justify-content-center active'>
                                                        <span className="d-flex align-items-center justify-content-center white_bg font_11 text_black checkout_round fw_ar_reg">3</span>
                                                        <span className="font_16 d-block text_black fw_ar_reg pl_10">Payment</span>
                                                    </li>
                                                </ul>
                                                <span className="d-block text-center text_black font_12 fw_ar_reg">Step 3</span>
                                                <h4 className="font_16 text-center text_black mb_41 fw_ar_reg">Payment</h4>
                                                <div className="shipping_blk mb_26">
                                                    <div className="shipping_info border_btm d-flex align-items-center justify-content-between">
                                                        <div className="shipping_left d-flex align-items-center">
                                                            <span className="d-block font_16 items_text fw_ar_reg contact_label">Contact </span>
                                                            <span className="d-block pl_6 font_16 text_black fw_ar_reg left_methid">{this.state.billing_email_id}</span>
                                                        </div>
                                                        <div className="ship_rgt d-flex">
                                                            <a href="/checkout" className="font_16 items_text d-flex text_underline fw_ar_reg">Change</a>
                                                        </div>
                                                    </div>
                                                    <div className="shipping_info border_btm d-flex align-items-center justify-content-between">
                                                        <div className="shipping_left d-flex align-items-center">
                                                            <span className="d-block font_16 items_text fw_ar_reg contact_label">Billing Address </span>
                                                            <span className="d-block pl_6 font_16 text_black fw_ar_reg left_methid ">{[this.state.billing_address_first, ',', this.state.billing_address_second, ',', this.state.billing_city_1, ',', this.state.billing_state_1, ',', this.state.billing_postcode_1]}</span>
                                                        </div>
                                                        <div className="ship_rgt d-flex">
                                                            <a href="/checkout" className="font_16 items_text d-flex text_underline fw_ar_reg">Change</a>
                                                        </div>
                                                    </div>
                                                    <div className="shipping_info border_btm d-flex align-items-center justify-content-between">
                                                        <div className="shipping_left d-flex align-items-center">
                                                            <span className="d-block font_16 items_text fw_ar_reg contact_label">Shipping Address </span>
                                                            <span className="d-block pl_6 font_16 text_black fw_ar_reg left_methid">{[this.state.shipping_address_first, ',', this.state.shipping_address_second, ',', this.state.shipping_city_2, ',', this.state.shipping_state_2, ',', this.state.shipping_postcode_2]}</span>
                                                        </div>
                                                        <div className="ship_rgt d-flex">
                                                            <a href="/checkout" className="font_16 items_text d-flex text_underline fw_ar_reg">Change</a>
                                                        </div>
                                                    </div>
                                                    <div className="shipping_info d-flex align-items-center justify-content-between">
                                                        <div className="shipping_left d-flex align-items-center">
                                                            <span className="d-block font_16 items_text fw_ar_reg contact_label">Method </span>
                                                            <div className="left_methid d-flex align-items-center p-0">
                                                                {/* <label htmlFor="" className="d-flex align-items-center checkbox_label radio_label m-0"> */}

                                                                <span className="checbox"></span>
                                                                {
                                                                    (this.state.items.length <= 0 && this.state.bundleItems.length <= 0) ? ''
                                                                        :
                                                                        <Fragment>
                                                                            {
                                                                                // Start cart shipping
                                                                                (this.state.freeShipping == 0) ?
                                                                                    <Fragment>
                                                                                        {
                                                                                            (this.state.shippingMethods.length <= 0) ? null :
                                                                                                this.state.shippingMethods.map(function (shipping, key) {
                                                                                                    if (shipping.allow_for_coupon == 0 && ((this.state.shipping_method_id == shipping.id || GET_STORAGE("cartMethodId") == shipping.id))) {
                                                                                                        return (
                                                                                                            <Fragment key={key}>
                                                                                                                {/* <li className="d-flex align-items-center flex-wrap mb_7">
                                                                                                                    <span className="checbox"></span>
                                                                                                                    <span className="d-flex align-items-center checkbox_label radio_label m-0">&ensp;{shipping.label} &emsp;&emsp;&emsp;{CURRENCY_FORMAT(shipping.cost)}</span>
                                                                                                                </li> */}
                                                                                                                <div className="shipping_left w-100 d-flex align-items-center">
                                                                                                                    <label className="d-flex align-items-center checkbox_label radio_label m-0">
                                                                                                                        {
                                                                                                                            (this.state.shipping_method_id == shipping.id || GET_STORAGE("cartMethodId") == shipping.id) ?
                                                                                                                                <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} onClick={this.changeCartShippingMethod} price={shipping.cost} cid={shipping.id} defaultChecked="checked" type="radio" name="orderShippingMethod" />
                                                                                                                                :
                                                                                                                                <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} onClick={this.changeCartShippingMethod} price={shipping.cost} cid={shipping.id} type="radio" name="orderShippingMethod" />
                                                                                                                        }
                                                                                                                        <span className="checbox"></span>
                                                                                                                        <span className="d-block pl_6 font_16 text_black fw_ar_reg">{shipping.label} {CURRENCY_FORMAT(shipping.cost)}</span>
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                            </Fragment>
                                                                                                        )
                                                                                                    }
                                                                                                }.bind(this))
                                                                                        }
                                                                                    </Fragment>
                                                                                    :
                                                                                    <Fragment>
                                                                                        {
                                                                                            (this.state.shippingMethods.length <= 0) ? null :
                                                                                                this.state.shippingMethods.map(function (shipping, key) {
                                                                                                    if (GET_STORAGE("cartMethodId") == shipping.id) {
                                                                                                        SET_STORAGE("cartMethodId", shipping.id);
                                                                                                        return (
                                                                                                            <Fragment key={key}>
                                                                                                                <div className="shipping_left  d-flex align-items-center">
                                                                                                                    <label className="d-flex align-items-center checkbox_label radio_label m-0">
                                                                                                                        {
                                                                                                                            (this.state.shipping_method_id == shipping.id || GET_STORAGE("cartMethodId") == shipping.id) ?
                                                                                                                                <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} onClick={this.changeCartShippingMethod} price={shipping.cost} cid={shipping.id} defaultChecked="checked" type="radio" name="orderShippingMethod" />
                                                                                                                                :
                                                                                                                                <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} onClick={this.changeCartShippingMethod} price={shipping.cost} cid={shipping.id} type="radio" name="orderShippingMethod" />
                                                                                                                        }
                                                                                                                        <span className="checbox"></span>
                                                                                                                        <span className="d-block pl_6 font_16 text_black fw_ar_reg">{shipping.label}</span>
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                            </Fragment>
                                                                                                        )
                                                                                                    }
                                                                                                }.bind(this))
                                                                                        }
                                                                                    </Fragment>
                                                                                // End cart shipping
                                                                            }
                                                                        </Fragment>
                                                                }
                                                                {/* </label> */}
                                                            </div>
                                                        </div>
                                                        <div className="ship_rgt d-flex">
                                                            <a href="/checkout-shipping" className="font_16 items_text d-flex text_underline fw_ar_reg">Change</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                { 
                                                (this.state.cartTotal > 0) ? 
                                                <div className="payment_method mb_26">
                                                    <h4 className="font_16 text_black fw_ar_reg mb_7">Payment</h4>
                                                    <p className="para mb_14 font_12 text_black fw_ar_reg">All transactions are secure and encrypted.</p>
                                                    {this.state.payment_method.map((payment, pay_index) => (
                                                        <div className='pay_lk payment_div_method authorize_blk position-relative'>

                                                            {
                                                                (
                                                                    (this.state.cartTotal < 0 && GET_STORAGE("meals") && this.state.meals.subscription == 'no')
                                                                    ||
                                                                    (this.state.cartTotal < 0 && COUNT_SUBSCRIPTION() == 0 && !GET_STORAGE("meals"))
                                                                ) ? "" :
                                                                (payment.gateway_class == 'authorize' && this.state.cartTotal > 0) || (payment.gateway_class == 'sezzle' && this.state.cartTotal > 0) || (payment.gateway_class == 'paypal' && this.state.cartTotal > 0) ?
                                                                        <Fragment>
                                                                           <input onChange={this.changeHandler} type="radio" name="select_payment" id="select_payment" className={`select_payment ${payment.gateway_class == "paypal" && (this.state.hasSubscriptionYes == false || this.state.hasSubscriptionNo == false) ? "paypal" : payment.gateway_class == "sezzle" && this.state.hasSubscriptionYes == true ? "disabled" : payment.gateway_class == "paypal" ? "disabled" : "abled"}`} value={payment.gateway_class} defaultChecked={pay_index == 0 ? 'checked' : ''} disabled={payment.gateway_class == "sezzle" && this.state.hasSubscriptionYes == true} />
                                                                            <div className={`pay_header d-flex align-items-center justify-content-between`}>
                                                                                <label className="d-flex align-items-center credit_card_radio checkbox_label radio_label m-0 mr_32">
                                                                                    <span className="checbox"></span>
                                                                                    {/* <span className={classnames('pl_6 text_black font_16 fw_ar_reg payment_title', payment.gateway_class == 'authorize' ? 'd-block' : 'd-none')}>{payment.title}</span> */}
                                                                                </label>

                                                                                {(payment.gateway_class == 'authorize') ?
                                                                                    (
                                                                                        (this.state.cartTotal < 0 && GET_STORAGE("meals") && this.state.meals.subscription == 'no')
                                                                                        ||
                                                                                        (this.state.cartTotal < 0 && COUNT_SUBSCRIPTION() == 0 && !GET_STORAGE("meals"))
                                                                                    ) ? "" :

                                                                                        <Fragment>
                                                                                            <div className="d-flex align-items-center justify-content-between auth_div">
                                                                                                <label htmlFor="payment_method_icanpay" className='font_14 text_black m-0 fw_ar_semi'> {payment.title}  </label>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <img src={CreditCard} alt={this.state.payment_method} />
                                                                                                    <a href="javascript:void(0);" className="choose_text font_12 fw_ar_reg ml_13 d-flex">and more...</a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Fragment>
                                                                                    : (payment.gateway_class == 'paypal' )?
                                                                                        <Fragment>
                                                                                            <div className="d-flex align-items-center justify-content-between auth_div">
                                                                                                <label htmlFor="payment_method_icanpay" className='font_14 text_black m-0 fw_ar_semi'> {payment.title}  </label>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <img src={PayPalIcon } className='ml_6 sezzle_img' alt={this.state.payment_method} />
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                ((this.state.hasSubscriptionYes == false || this.state.hasSubscriptionNo == false)) ? "" :  <span className='font_14 red_text fw_ar_reg'>*One-time product and subscription product not available with PayPal. Please separate products onto two separate orders to proceed with PayPal.</span>
                                                                                            }
                                                                                        </Fragment>
                                                                                        : (payment.gateway_class == 'sezzle')?
                                                                                        <Fragment>
                                                                                            <div className="d-flex align-items-center justify-content-between auth_div">
                                                                                                <label htmlFor="payment_method_icanpay" className='font_14 text_black m-0 fw_ar_semi'> {payment.title}  </label>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <img src={SezzleIcon} className='ml_6 sezzle_img' alt={this.state.payment_method} />
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                ((this.state.hasSubscriptionYes == false)) ? "" :  <span className='font_14 red_text fw_ar_reg'>*Sezzle is unavailable for subscribe and save.</span>
                                                                                            }
                                                                                        </Fragment> : ""
                                                                                }
                                                                            </div>
                                                                        </Fragment> : ""
                                                            }

                                                            {(payment.gateway_class == 'authorize') ?
                                                                (
                                                                    (this.state.cartTotal < 0 && GET_STORAGE("meals") && this.state.meals.subscription == 'no')
                                                                    ||
                                                                    (this.state.cartTotal < 0 && COUNT_SUBSCRIPTION() == 0 && !GET_STORAGE("meals"))
                                                                ) ? "" :

                                                                    <Fragment>
                                                                        {/* <div className="cpay_body form_input_blk"> */}

                                                                        <div className="cpay_body form_input_blk auth_hide">
                                                                            <Row>
                                                                                <Col md={12}>
                                                                                    <div className="mb_12">
                                                                                        <input onChange={this.changeHandler} value={this.state.name_on_card} type="text" required={this.state.authorize_state} className={classnames("form-control height_42", { 'pl_error_input': errors.name_on_card })} name="name_on_card" placeholder="Card Holder Name" />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col md={12}>
                                                                                    <div className="mb_12">
                                                                                        <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} value={this.state.card_number} required={this.state.authorize_state} type="text" className={classnames("form-control height_42", { 'pl_error_input': errors.card_number })} name="card_number" maxLength="16" autoComplete="off" placeholder="Card Number" />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <div className="mb_12 d-flex">
                                                                                        <select disabled={this.state.isFormDisabled} onChange={this.changeHandler} required={this.state.authorize_state} className={classnames("pull-left form-control height_42", { 'pl_error_input': errors.expire_month })} name="expire_month" >
                                                                                            <option value="">Month</option>
                                                                                            <CartMonths />
                                                                                        </select>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <div className="mb_12 d-flex">
                                                                                        <select disabled={this.state.isFormDisabled} onChange={this.changeHandler} required={this.state.authorize_state} className={classnames("pull-right form-control height_42", { 'pl_error_input': errors.expire_year })} name="expire_year" >
                                                                                            <option value="">Year</option>
                                                                                            <CartYears />
                                                                                        </select>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col md={12}>
                                                                                    <div className="mb_12">
                                                                                        <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} required={this.state.authorize_state} value={this.state.cvv} type="text" className={classnames("form-control height_42", { 'pl_error_input': errors.cvv })} name="cvv" maxLength="4" placeholder="CVV" />
                                                                                    </div>
                                                                                </Col>
                                                                                {/* <Col md={12}>
                                                                                <div className="mb_12 d-flex align-items-center w-100">
                                                                                    <Row className='upi_row'>
                                                                                        <Col md={4}>
                                                                                            <label htmlFor="" className="d-flex payment_upi align-items-center checkbox_label radio_label m-0 w-100">
                                                                                                <input type="radio" />
                                                                                                <span className="checbox"></span>
                                                                                                <img src={PayPalIcon} alt="paypal_icon" className='ml_6' />
                                                                                            </label>
                                                                                        </Col>
                                                                                        <Col md={4}>
                                                                                            <label htmlFor="" className="d-flex payment_upi align-items-center checkbox_label radio_label m-0 w-100">
                                                                                                <input type="radio" />
                                                                                                <span className="checbox"></span>
                                                                                                <img src={AmazonIcon} alt="amazon icon" className='ml_6' />
                                                                                            </label>
                                                                                        </Col>
                                                                                        <Col md={4}>
                                                                                            <label htmlFor="" className="d-flex payment_upi align-items-center checkbox_label radio_label m-0 w-100">
                                                                                                <input type="radio" />
                                                                                                <span className="checbox"></span>
                                                                                                <img src={SezzleIcon} alt="sezzle icon" className='ml_6' />
                                                                                            </label>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </Col> */}
                                                                                {/* <Col md={4}>
                                                                                    <label htmlFor="" className="d-flex payment_upi align-items-center checkbox_label radio_label m-0 w-100">
                                                                                        <input type="radio" />
                                                                                        <span className="checbox"></span>
                                                                                        <img src={SezzleIcon} alt="sezzle icon" className='ml_6' />
                                                                                    </label>
                                                                                </Col> */}
                                                                            </Row>
                                                                        </div>

                                                                    </Fragment>
                                                                : ""}

                                                        </div>
                                                    ))}

                                                </div> : ""
                                                }
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="">
                                                            <label className="d-flex align-items-center checkbox_label m-0 ">
                                                                <input disabled={this.state.isFormDisabled} onClick={this.agreeTermsPolicy} id="agreeTermsPolicy" type="checkbox" className="form-check-input" />
                                                                <span className="checbox"></span>
                                                                <span className="pl_10 text_black font_16 fw_ar_reg">I agree to the <NavLink className="font_16 text_black text_underline" to={`${this.state.terms_of_use}`} target="_blank">Terms and Conditions</NavLink> and <NavLink className="font_16 text_black text_underline" to={`${this.state.privacy_policy}`} target="_blank">Privacy Policy</NavLink>. <NavLink className="font_16 text_black text_underline" to="/cancellation-process" target="_blank">Cancellation Process & Information</NavLink></span>
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {/* <div className="payment_method mb_26">
                                                <h4 className="font_16 text_black fw_ar_reg mb_7">Billing Address</h4>
                                                <p className="para mb_14 font_12 text_black fw_ar_reg">Billing Address</p>
                                                <div className="shipping_blk">
                                                    <div className="shipping_info border_btm d-flex align-items-center justify-content-between">
                                                        <div className="shipping_left d-flex align-items-center">
                                                            <label htmlFor="" className="d-flex align-items-center checkbox_label radio_label m-0 mr_32">
                                                                <input type="radio" name="agree_to_receive_text"  />
                                                                <span className="checbox"></span>
                                                                <span className="pl_6 text_black font_16 fw_ar_reg">Same as shipping address</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="shipping_info d-flex align-items-center justify-content-between">
                                                        <div className="shipping_left d-flex align-items-center">
                                                            <label htmlFor="" className="d-flex align-items-center checkbox_label radio_label m-0 mr_32">
                                                                <input  type="radio" name="agree_to_receive_text"  />
                                                                <span className="checbox"></span>
                                                                <span className="pl_6 text_black font_16 fw_ar_reg">Use a different billing address</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                                <div className="mt_43 d-flex">
                                                    <a href='/checkout-shipping' className="d-flex align-items-center">
                                                        <img src={BackArrow} alt="BackArrow" />
                                                        <span className="d-block pl_4 font_12 text_black fw_ar_reg">Return to shipping</span>
                                                    </a>
                                                </div>
                                            </Col>
                                            <Col md={4} className="checkout_rgt_blk">

                                                <div className="cart_summary_blk white_bg mb_41">
                                                    <div className="d-flex align-items-center mb_18">
                                                        <img src={CartSummaryIcon} alt="cart summary icon" />
                                                        <span className="d-block pl_6 font_14 let_spa_2 text_black">Cart Summary</span>
                                                    </div>
                                                    {
                                                        (this.state.items.length <= 0) ? null :
                                                            this.state.items.map(function (item, key) {
                                                                return (
                                                                    <Fragment key={key}>
                                                                        <div className="cart_pro_su d-flex align-items-start justify-content-between">
                                                                            <div className="left_blk">
                                                                                <span className="d-block text_black left_spa_2 font_16 fw_el_bold">{item.cart_product_name}</span>
                                                                                <span className="d-block items_text left_spa_2 font_12 fw_ar_reg">{item.cart_variation_name}</span>
                                                                            </div>
                                                                            <div className="right_blk">
                                                                                <span className="d-block font_14 fw_ar_bold text_black text-right">
                                                                                    {
                                                                                        (item.subscription == "yes") ?
                                                                                            CURRENCY_FORMAT(item.quantity * item.cart_discount_price)
                                                                                            :
                                                                                            CURRENCY_FORMAT(item.quantity * item.cart_sale_price)
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </Fragment>
                                                                )
                                                            }.bind(this))
                                                    }
                                                    {
                                                        (this.state.bundleItems == null) ? null :
                                                        (this.state.bundleItems.length <= 0) ? null :
                                                            <Fragment>
                                                                <div className="cart_pro_su d-flex align-items-start justify-content-between">
                                                                    <div className="left_blk">
                                                                        <span className="d-block text_black left_spa_2 font_16 fw_el_bold">Product Bundle</span>
                                                                    </div>
                                                                    <div className="right_blk">
                                                                        <span className="d-block font_14 fw_ar_bold text_black text-right">
                                                                            {CURRENCY_FORMAT(BUNDLE_SUB_TOTAL())}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                    }

                                                    <ul className="pro_cart_det mt_66">
                                                        {
                                                            (this.state.items.length <= 0) ? '' :
                                                            <li className="d-flex align-items-center flex-wrap mb_7">
                                                                <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Sub Total</span>
                                                                <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(Number(CART_SUB_TOTAL()) + Number(MEAL_SUB_TOTAL()))}</span>
                                                            </li>
                                                        }
                                                        {
                                                            (this.state.bundleItems == null) ? '' :
                                                            (this.state.bundleItems.length <= 0) ? '' :
                                                            <li className="d-flex align-items-center flex-wrap mb_7">
                                                                <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Sub Total</span>
                                                                <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(Number(BUNDLE_SUB_TOTAL()) + Number(MEAL_SUB_TOTAL()))}</span>
                                                            </li>
                                                        }

                                                        {
                                                            // Start coupon list
                                                            (this.state.coupons.length <= 0) ? "" :
                                                                this.state.coupons.map(function (coupon, key) {

                                                                    if (coupon.free_shipping == 1 && coupon.coupon_type == "product") {
                                                                        this.setState({
                                                                            freeShipping: 1,
                                                                            cartShippingCost: 0,
                                                                        })
                                                                    } else if (coupon.free_shipping == 1 && coupon.coupon_type == "meal") {
                                                                        this.setState({
                                                                            mealShippingCostStatus: false,
                                                                        })
                                                                    } else if (coupon.free_shipping == 1 && coupon.coupon_type == "any") {
                                                                        this.setState({
                                                                            freeShipping: 1,
                                                                            cartShippingCost: 0,
                                                                            mealShippingCostStatus: false
                                                                        });
                                                                    }

                                                                    return (
                                                                        <Fragment key={key}>
                                                                            <li className="d-flex align-items-center flex-wrap mb_7">
                                                                                <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">COUPON: {coupon.coupon_code}</span>
                                                                                <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(coupon.discount_amount)}</span>
                                                                                <a onClick={(e) => this.deleteCoupon(e, key)} href="#" className='red_text font_14 w-100 d-flex fw_ar_reg'>Delete</a>
                                                                            </li>
                                                                        </Fragment>
                                                                    )
                                                                }.bind(this))
                                                            // End coupon list
                                                        }


                                                        {
                                                            (this.state.items.length <= 0 && this.state.bundleItems.length <= 0) ? ''
                                                                :
                                                                <Fragment>
                                                                    {
                                                                        // Start cart shipping

                                                                        (this.state.freeShipping == 0) ?
                                                                            <Fragment>
                                                                                {
                                                                                    (this.state.shippingMethods.length <= 0) ? null :
                                                                                        this.state.shippingMethods.map(function (shipping, key) {
                                                                                            if (shipping.allow_for_coupon == 0 && ((this.state.shipping_method_id == shipping.id || GET_STORAGE("cartMethodId") == shipping.id))) {
                                                                                                return (
                                                                                                    <Fragment key={key}>
                                                                                                        <li className="d-flex align-items-center flex-wrap mb_7">
                                                                                                            <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Shipping Cost</span>
                                                                                                            <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(shipping.cost)}</span>
                                                                                                        </li>
                                                                                                    </Fragment>
                                                                                                )
                                                                                            }
                                                                                        }.bind(this))
                                                                                }
                                                                            </Fragment>
                                                                            :
                                                                            <Fragment>
                                                                                {
                                                                                    (this.state.shippingMethods.length <= 0) ? null :
                                                                                        this.state.shippingMethods.map(function (shipping, key) {
                                                                                            if (GET_STORAGE("cartMethodId") == 1) {
                                                                                                this.setState({
                                                                                                    cartShippingCost: shipping.cost
                                                                                                });
                                                                                            } else {
                                                                                                this.setState({
                                                                                                    cartShippingCost: 0
                                                                                                });
                                                                                            }
                                                                                            if ((this.state.shipping_method_id == shipping.id || GET_STORAGE("cartMethodId") == shipping.id)) {
                                                                                                SET_STORAGE("cartMethodId", shipping.id);
                                                                                                return (
                                                                                                    <Fragment key={key}>
                                                                                                        <li className="d-flex align-items-center flex-wrap mb_7">
                                                                                                            <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Shipping Cost</span>
                                                                                                            <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(this.state.cartShippingCost)}</span>
                                                                                                        </li>
                                                                                                    </Fragment>
                                                                                                )
                                                                                            }
                                                                                        }.bind(this))
                                                                                }
                                                                            </Fragment>
                                                                        // End cart shipping
                                                                    }
                                                                </Fragment>
                                                        }

                                                        {/* Start meal shipping cost for total */}
                                                        {
                                                            (this.state.meals != null && this.state.meals != '' && this.state.meals.items.length > 0) ?
                                                                <Fragment>
                                                                    <li className="d-flex align-items-center flex-wrap mb_7">
                                                                        <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Meal Shipping Cost</span>
                                                                        <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg"> {
                                                                            (this.state.meals.shipping_cost > 0 && this.state.mealShippingCostStatus == true) ?
                                                                                CURRENCY_FORMAT(this.state.meals.shipping_cost)
                                                                                : "Free Shipping"
                                                                        }</span>
                                                                    </li>
                                                                </Fragment>
                                                                : ''
                                                        }
                                                        {/* End meal shipping cost for total */}


                                                        {
                                                            (this.state.taxStatus == 0) ? ''
                                                                :
                                                                <Fragment>
                                                                    <li className="d-flex align-items-center flex-wrap mb_7">
                                                                        <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Tax</span>
                                                                        <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(Number(this.state.tax_amount) + Number(this.state.meal_tax_amount))}</span>
                                                                    </li>
                                                                </Fragment>
                                                        }
                                                        <li className="d-flex align-items-center flex-wrap mb_7">
                                                            <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Total</span>
                                                            <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg"> {CURRENCY_FORMAT(CART_TOTAL_CURRENCY_FORMAT(this.state.cartTotal))}</span>
                                                        </li>

                                                        {
                                                            (COUNT_SUBSCRIPTION() == 0) ? null :
                                                                <Fragment>
                                                                    <li>
                                                                        <span className="mb_7 total_span let_spa_2 d-block font_14 fw_ar_med">Recurring Totals</span>
                                                                    </li>
                                                                    <li className="d-flex align-items-center flex-wrap mb_7">
                                                                        <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Subtotal</span>
                                                                        <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg"> {CURRENCY_FORMAT(RECURRING_CART_SUB_TOTAL())}</span>
                                                                    </li>


                                                                    {
                                                                        // Start recurring cart shipping
                                                                        (this.state.shippingMethods.length <= 0) ? null :
                                                                            this.state.shippingMethods.map(function (shipping, key) {
                                                                                if (shipping.allow_for_coupon == 0 && GET_STORAGE("cartMethodId") == shipping.id) {
                                                                                    return (
                                                                                        <Fragment key={key}>
                                                                                            <li className="d-flex align-items-center flex-wrap mb_7">
                                                                                                <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Shipping Cost</span>
                                                                                                <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">  {CURRENCY_FORMAT(shipping.cost)}</span>
                                                                                            </li>
                                                                                        </Fragment>
                                                                                    )
                                                                                }
                                                                            }.bind(this))
                                                                        // End recurring cart shipping
                                                                    }
                                                                    {
                                                                        (this.state.taxStatus == 0) ? ''
                                                                            :
                                                                            <Fragment>
                                                                                <li className="d-flex align-items-center flex-wrap mb_7">
                                                                                    <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Tax</span>
                                                                                    <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(this.state.subscription_tax_amount)}</span>
                                                                                </li>
                                                                            </Fragment>
                                                                    }

                                                                    <li className="d-flex align-items-center flex-wrap mb_7">
                                                                        <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Recurring Total</span>
                                                                        <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg"> {
                                                                            CURRENCY_FORMAT(Number(RECURRING_CART_SUB_TOTAL()) + Number(this.state.recurringCartShippingCost) + Number(this.state.subscription_tax_amount))
                                                                        }</span>
                                                                    </li>
                                                                    <li className="d-flex align-items-center flex-wrap mb_7">
                                                                        <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">First renewal</span>
                                                                        <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg"> {NEXT_MONTH()}</span>
                                                                    </li>
                                                                </Fragment>
                                                        }

                                                        {
                                                            (this.state.meals.length <= 0 || this.state.meals.subscription == 'no') ? null :
                                                                <Fragment>
                                                                    <li>
                                                                        <span className="mb_7 total_span let_spa_2 d-block font_14 fw_ar_med">Meal Recurring Totals</span>
                                                                    </li>

                                                                    <li className="d-flex align-items-center flex-wrap mb_7">
                                                                        <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Subtotal</span>
                                                                        <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(MEAL_SUB_TOTAL())}</span>
                                                                    </li>
                                                                    <li className="d-flex align-items-center flex-wrap mb_7">
                                                                        <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Meal SHIPPING COST</span>
                                                                        <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">
                                                                            {
                                                                                (this.state.meals.shipping_cost > 0) ?
                                                                                    CURRENCY_FORMAT(this.state.meals.shipping_cost)
                                                                                    : "Free Shipping"
                                                                            }
                                                                        </span>
                                                                    </li>
                                                                    {
                                                                        (this.state.taxStatus == 0) ? ''
                                                                            :
                                                                            <Fragment>
                                                                                <li className="d-flex align-items-center flex-wrap mb_7">
                                                                                    <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Tax</span>
                                                                                    <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(this.state.subscription_meal_tax_amount)}</span>
                                                                                </li>
                                                                            </Fragment>
                                                                    }
                                                                    <li className="d-flex align-items-center flex-wrap mb_7">
                                                                        <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Meal Recurring Total</span>
                                                                        <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">  {
                                                                            CURRENCY_FORMAT(Number(MEAL_TOTAL()) + Number(this.state.subscription_meal_tax_amount))
                                                                        }</span>
                                                                    </li>
                                                                    <li className="d-flex align-items-center flex-wrap mb_7">
                                                                        <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">First renewal</span>
                                                                        <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg"> {NEXT_WEEK(duration_id)}</span>
                                                                    </li>
                                                                </Fragment>
                                                        }

                                                    </ul>
                                                </div>
                                                {/* End cart view */}


                                                <div className="w-100">
                                                    <div class="w-100">
                                                        <Fragment>
                                                            {
                                                                (this.state.agreeTermsPolicy) ?
                                                                    <button disabled={this.state.isLoading} type="submit" className={this.state.agreeTermsPolicy ? "w-100 justify-content-center d-flex cont_ship_btn shop_more take_quiz_btn font_16 text_white fw_ar_reg" : "w-100 justify-content-center d-flex cont_ship_btn shop_more take_quiz_btn font_16 text_white fw_ar_reg disable"} >{place_order_loading ? "Please Wait..." : "Place Order"}</button>
                                                                    :
                                                                    <button disabled className="w-100 justify-content-center d-flex cont_ship_btn shop_more take_quiz_btn font_16 text_white fw_ar_reg disable">Place Order</button>
                                                            }
                                                        </Fragment>
                                                        {/* <button type="submit" name="" value="" class="w-100 justify-content-center d-flex cont_ship_btn shop_more take_quiz_btn font_16 text_white fw_ar_reg">{place_order_loading ? "Please Wait..." : "Continue To Payment"}</button></div> */}
                                                    </div>
                                                    {/* <a href='/order-confirmed' className="w-100 cont_ship_btn shop_more take_quiz_btn font_16 text_white fw_ar_reg w-100 d-flex justify-content-center">Continue To Payment</a> */}
                                                </div>
                                                {/* <div className="checkout-meal-shipping-shedule">
                                                    {
                                                        (this.state.meals != null && this.state.meals != '' && this.state.meals.items.length > 0) ?
                                                            <ShippingSchedule />
                                                            : ""
                                                    }
                                                </div> */}

                                                {/* Start payment method */}

                                                {/* warning modal start  */}
                                                <div className="modal fade" id="warningModal" tabIndex="-1" role="dialog" aria-labelledby="warningModal" aria-hidden="true">
                                                    <div className="modal-dialog modal-lg" role="document" style={{ marginTop: '5%' }}>
                                                        <div className="modal-content warningModalContent">
                                                            <div className="modal-header cus-modal-header">
                                                                <h3>Warnings</h3>
                                                                <button type="button" className="close pl-warning-close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body montserrat pl-warning-body">
                                                                <div className="mt-2 warning-block-cards" id="warningModalContent"></div>
                                                                <div className="d-flex align-items-center justify-content-center py-3">
                                                                    <div className="d-flex flex-column">
                                                                        <button className="btn mr-4 btn-close" onClick={this.warningModalCancelHandel} type="button" >Cancel</button>
                                                                        <span className="warning-cancel-help-text">Remove item(s) from the cart</span>
                                                                    </div>
                                                                    <div className="d-flex flex-column">
                                                                        <button className="btn btn-confirm" data-dismiss="modal">OK Proceed</button>
                                                                        <sapn className="warning-cancel-help-text"></sapn>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* warning modal end  */}

                                                {/* canadaRestrictedProductModal start  */}

                                                <div className="modal fade" id="canadaRestrictedProductModal" tabIndex="-1" role="dialog" aria-labelledby="canadaRestrictedProductModal" aria-hidden="true">
                                                    <div className="modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-header cus-modal-header">
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body montserrat">
                                                                <div className="text-danger cus-modal-title">Alert!</div>
                                                                <p className="modal-body-content" style={{ fontWeight: 'bold' }} id="canadaRestrictedProductModalBody"></p>
                                                            </div>
                                                            <div className="modal-footer" style={{ justifyContent: "center" }}>
                                                                <a href="/cart" className="">Back to Cart</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* canadaRestrictedProductModal end  */}

                                                {/* Product stockout check modal start  */}

                                                <div className="modal fade" id="stockoutErrorModal" tabIndex="-1" role="dialog" aria-labelledby="stockoutErrorModal" aria-hidden="true">
                                                    <div className="modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-header cus-modal-header">
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body montserrat">
                                                                <div className="text-danger cus-modal-title">Alert!</div>
                                                                <p className="modal-body-content">{Parser(this.state.stockoutErrorMessage)}</p>
                                                                <NavLink to="#" onClick={this.backToCart} className="wc-forward">Back to Cart</NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Product stock check modal end  */}
                                            </Col>
                                        </Row>
                                        {/* warning modal start  */}
                                        <div className="modal fade" id="warningModal" tabIndex="-1" role="dialog" aria-labelledby="warningModal" aria-hidden="true">
                                            <div className="modal-dialog modal-lg" role="document" style={{ marginTop: '5%' }}>
                                                <div className="modal-content warningModalContent">
                                                    <div className="modal-header cus-modal-header">
                                                        <h3>Warnings</h3>
                                                        <button type="button" className="close pl-warning-close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body montserrat pl-warning-body">
                                                        <div className="mt-2 warning-block-cards" id="warningModalContent"></div>
                                                        <div className="d-flex align-items-center justify-content-center py-3">
                                                            <div className="d-flex flex-column">
                                                                <button className="btn mr-4 btn-close" onClick={this.warningModalCancelHandel} type="button" >Cancel</button>
                                                                <span className="warning-cancel-help-text">Remove item(s) from the cart</span>
                                                            </div>
                                                            <div className="d-flex flex-column">
                                                                <button className="btn btn-confirm" data-dismiss="modal">OK Proceed</button>
                                                                <sapn className="warning-cancel-help-text"></sapn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* warning modal end  */}

                                        {/* canadaRestrictedProductModal start  */}

                                        <div className="modal fade" id="canadaRestrictedProductModal" tabIndex="-1" role="dialog" aria-labelledby="canadaRestrictedProductModal" aria-hidden="true">
                                            <div className="modal-dialog" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header cus-modal-header">
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body montserrat">
                                                        <div className="text-danger cus-modal-title">Alert!</div>
                                                        <p className="modal-body-content" style={{ fontWeight: 'bold' }} id="canadaRestrictedProductModalBody"></p>
                                                    </div>
                                                    <div className="modal-footer" style={{ justifyContent: "center" }}>
                                                        <a href="/cart" className="">Back to Cart</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* canadaRestrictedProductModal end  */}

                                        {/* Product stockout check modal start  */}

                                        <div className="modal fade" id="stockoutErrorModal" tabIndex="-1" role="dialog" aria-labelledby="stockoutErrorModal" aria-hidden="true">
                                            <div className="modal-dialog" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header cus-modal-header">
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body montserrat">
                                                        <div className="text-danger cus-modal-title">Alert!</div>
                                                        <p className="modal-body-content">{Parser(this.state.stockoutErrorMessage)}</p>
                                                        <NavLink to="#" onClick={this.backToCart} className="wc-forward">Back to Cart</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Product stock check modal end  */}
                                    </form>
                                </div>
                            </div>
                        </Fragment>
                        
                }
                 
            </Fragment>
        );
    }
}

CheckoutPayment.propTypes = {
    checkoutRequest: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    meals: PropTypes.array.isRequired,
    setUserLogin: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        meals: state.meals
    };
}

export default connect(mapStateToProps, { checkoutRequest, setUserLogin })(CheckoutPayment);
