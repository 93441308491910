import React, { Fragment, PureComponent } from 'react';
import Parser from 'html-react-parser';
import { NavLink } from 'react-router-dom';
import { AJAX_PUBLIC_REQUEST, GET_STORAGE } from '../../Constants/AppConstants';
import SingleProductListGrid from './SingleProductListGrid';
import SingleProductModal from './SingleProductModal';
import Pagination from '../Common/Pagination';
import SingleProductNotAvailableModal from './SingleProductNotAvailableModal';
import SingleProductNotAvailable from './SingleProductNotAvailable'
import SearchIcon from './../../Assets/images/customer_images/header_search.svg'
import HomeIcon from './../../Assets/images/refer_images/home_icon.svg'

class TaggedProducts extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tag_id: this.props.match.params.tag_id,
            products: [],
            products_search: [],
            productDetails: {},
            listView: false,
            ProductLink: '',
            productNotAvailable: ["Bulletproof Vitality For Her", "Women's Ultimate", "Women's Immune Booster"],
            total_records: 0,
            total_page: 0,
            per_page: 0,
            pagenum: 1,
            products_filtered: [],
            products_filters: [],
            searchName: '',
            tagName: ''
        }
        document.title = "Products - Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        document.body.classList.remove('home_page');
        this.getTaggedProducts(this.state.pagenum);
        this.getSearchProducts();
        let tagData = JSON.parse(GET_STORAGE('tagData'));
        let selectedTag = tagData.find(tag => tag.id === this.state.tag_id);
        this.setState({ tagName: selectedTag.tag_name });
    }

    pagenationHandle = (pageNumber) => {
        this.setState({ loading: true });
        this.getTaggedProducts(pageNumber);
        this.getSearchProducts();
    }

    gridListView = (e) => {
        this.setState({ listView: !this.state.listView })
    }

    filterBy = (e) => {
        let filter_by = e.target.value;
        this.setState({
            loading: true,
            [e.target.name]: filter_by
        })
        this.getTaggedProducts(1);
    }

    getTaggedProducts = (pageNumber) => {
        const data = {
            pagenum: parseInt(pageNumber),
            tag_id: this.state.tag_id
        }
        AJAX_PUBLIC_REQUEST("POST", "product/getList", data).then(results => {
            if (results.response.code === 1000) {
                this.setState({
                    products: results.response.data,
                    products_filtered: results.response.data,
                    loading: false,
                    // Pagination Config
                    total_records: parseInt(results.response.total_records),
                    total_page: parseInt(results.response.total_page),
                    per_page: parseInt(results.response.per_page),
                    pagenum: parseInt(results.response.pagenum),
                });
            } else {
                this.setState({
                    error: Parser("<p className='text-danger'>" + results.response.message + "</p>"),
                    loading: false,
                    // Pagination Config
                    item_count: 0,
                    total_records: 0,
                    total_page: 0,
                    per_page: 0,
                    pagenum: 1,
                })
            }
        });
    }

    getSearchProducts = () => {
        const data = {
            // pagenum: parseInt(pageNumber),
            // tag_id: this.state.tag_id
        }
        AJAX_PUBLIC_REQUEST("POST", "product/getsearchList", data).then(results => {
            if (results.response.code === 1000) {
                this.setState({
                    products_search: results.response.data,
                    loading: false,
                });
            } else {
                this.setState({
                    error: Parser("<p className='text-danger'>" + results.response.message + "</p>"),
                    loading: false,
                })
            }
        });
    }

    notAvailablePopup = (link) => {
        this.setState({
            ProductLink: link,
        });
    }

    //filter
    filterProduct = (e) => {
        if (e.target.value != '') {
            this.setState({
                searchName: e.target.value,
                products_filtered: this.state.products_search.filter(function (product) {
                    if (e.target.value === '') {
                        return product;
                    } else {
                        let string = product.title.toUpperCase();
                        let substring = e.target.value.toUpperCase();
                        if (string.includes(substring)) {
                            return product;
                        }
                    }
                }.bind(this))
            });
        } else {
            this.setState({
                searchName: e.target.value,
                products_filtered: this.state.products.filter(function (product) {
                    if (e.target.value === '') {
                        return product;
                    } else {
                        let string = product.title.toUpperCase();
                        let substring = e.target.value.toUpperCase();
                        if (string.includes(substring)) {
                            return product;
                        }
                    }
                }.bind(this))
            });
        }
    }

    render() {

        const quickView = this.quickView;
        const notAvailablePopup = this.notAvailablePopup;

        return (
            <Fragment>
                {
                    (this.state.loading) ?
                        <div className="loading container full_page_loader"></div>
                        :
                        <Fragment>
                            <div className="suplements_blk">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <main className="athletes_list_wrapper">


                                                <div className="d-flex align-items-center mb_28 justify-content-between">
                                                    <div className='pro_head_blk'>
                                                        <h2 className="product_itle text_black let_spa_2 font_24 fw_el_semi mb_6">{this.state.tagName} Product Lists</h2>
                                                        <nav aria-label="breadcrumb" className="m-0">
                                                            <ol className="breadcrumb cus_breadcrumb pro_breadcrumb">
                                                                <li className="breadcrumb-item"><NavLink to="/"><img src={HomeIcon} alt="home_icon" /></NavLink></li>
                                                                <li className="breadcrumb-item active" aria-current="page"> ~ {this.state.tagName}</li>
                                                            </ol>
                                                        </nav>
                                                    </div>
                                                    <div className="input_bundle_btn d-flex align-items-center justify-content-end">
                                                        <form className="product-search-form product_search_input nxt_bundle_btn position-relative form_input_blk" method="get">
                                                            <div className="search-input-group">
                                                                <input onChange={this.filterProduct} value={this.state.searchName}
                                                                    className="form-control" placeholder="Search" name="searchName" id="filter-by" type="text" />
                                                            </div>
                                                            <div className="search_icon position-absolute d-flex align-items-center justify-content-center">
                                                                <img src={SearchIcon} alt="search icon" />
                                                            </div>
                                                        </form>
                                                        <NavLink className="build_bundle add_cart_btn font_16 fw-reg d-block text-center" to={"/create-custom-bundle"} >Build Your Own Bundle</NavLink>
                                                    </div>
                                                </div>

                                                <div className="row sup_pro_row">
                                                    {this.state.products_filtered.length <= 0
                                                        ? <h3>No Product Added to {this.state.tagName}!</h3>
                                                        : this.state.products_filtered.map(
                                                            function (product, key) {
                                                                product.notAvailablePopup = this.state.productNotAvailable.includes(product.title) ? this.notAvailablePopup : "";
                                                                // Added product quickviewer
                                                                product.quickView = this.quickView;
                                                                return this.state.topSellerShow <= key ? (
                                                                    ""
                                                                ) :
                                                                    (this.state.productNotAvailable.includes(product.title)) ?
                                                                        <div key={Math.random()} className={this.state.listView ? 'col-xs-12 col-sm-4 col-md-4 col-lg-3 list_view' : 'col-xs-12 col-sm-4 col-md-4 col-lg-3'}>
                                                                            <SingleProductNotAvailable product={product} type='topSeller' />
                                                                        </div> :
                                                                        (
                                                                            <div
                                                                                key={Math.random()}
                                                                                className={
                                                                                    this.state.listView
                                                                                        ? "col-md-3 list_view"
                                                                                        : "col-md-3"
                                                                                }
                                                                            >
                                                                                <SingleProductListGrid
                                                                                    key={key}
                                                                                    product={product}
                                                                                />
                                                                            </div>
                                                                        );
                                                            }.bind(this)
                                                        )}
                                                </div>

                                                {this.state.searchName == '' ?
                                                    <Pagination
                                                        pagenationHandle={this.pagenationHandle}
                                                        total_records={this.state.total_records}
                                                        total_page={this.state.total_page}
                                                        per_page={this.state.per_page}
                                                        pagenum={this.state.pagenum}
                                                    />
                                                    : ""
                                                }
                                            </main>
                                        </div>
                                    </div>
                                    <SingleProductModal product={this.state.productDetails} />
                                    <SingleProductNotAvailableModal productLink={this.state.ProductLink} />
                                </div>
                            </div>
                        </Fragment>
                }
            </Fragment>
        );
    }
}

export default TaggedProducts;
