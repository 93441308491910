import validator from 'validator';
import isEmpty from 'lodash/isEmpty';

function removeSpaces(string){
    return string.split(' ').join('');
}

export default function collectEmailValidate(data) {
  let errors = {};

  if (validator.isEmpty(data.user_email)) {
    errors.user_email = 'This field is required';
  }
  if (!validator.isEmail(data.user_email)) {
    errors.user_email = 'Email is invalid';
  }
  
  return {
    errors,
    isValid: isEmpty(errors),
    isFormValid:isEmpty(errors)
  }
}