import React, { Fragment, PureComponent } from 'react';
import { NavLink } from "react-router-dom";
import ReactImageFallback from "react-image-fallback";
import { CURRENCY_FORMAT } from '../../Constants/AppConstants';

class SingleProductListGrid extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        const product = this.props.product;
        let regular_price = 0;
        if (product.hasOwnProperty("flavors") === false) {
            const variation = product.hasOwnProperty("variations") ? product.variations[product.first_month] : '';
            regular_price = variation ? variation.regular_price : null;
            this.setState({
                firstRegularPrice: CURRENCY_FORMAT(regular_price),
            });
        } else if (product.hasOwnProperty("flavors") === true) {
            const variation = product.hasOwnProperty("flavors") ? product.variations[product.first_month + '_' + product.flavors[0].id] : '';
            regular_price = variation ? variation.regular_price : null;
            this.setState({
                firstRegularPrice: CURRENCY_FORMAT(regular_price),
            });
        } else {
            this.setState({
                firstRegularPrice: null,
            })
        }
        
        return (
            <Fragment>
                <NavLink className="refer_produc_wrapper sup_pro_col h-100 d-flex flex-column align-items-center" to={`/product/${product.slug}`}>
                    <div className="refer-img-wrapper">
                        <ReactImageFallback
                            src={product.hasOwnProperty('list_image') ? product.list_image : ''}
                            fallbackImage={require('../../Assets/images/preloader.gif')}
                            initialImage={require('../../Assets/images/preloader.gif')}
                            alt={product.hasOwnProperty('title') ? product.title : ''}
                            className="img-fluid" />
                        {/* <div className="product_quick_view">
                            <div className="refer_product_select_option_wrapper">
                                <NavLink to={`/product/${product.slug}`} className="refer_product_select_option">Select Options</NavLink>
                            </div>
                            <div className="refer_product_quickview_wrapper">
                                <a onClick={() => product.quickView(product.product_id)} href="#" className="refer_product_quickview" data-toggle="modal" data-target="#productQucikView">Quickview</a>
                            </div>
                        </div> */}
                    </div>
                    <div className="refer_product_short_desc">
                        <h3 className="font_20 text_black fw_el_bold mb_4">{product.hasOwnProperty('title') ? product.title : ''}</h3>
                        <span className="font_16 d-block text_black fw_ar_bold">{product.hasOwnProperty('start_price') ? CURRENCY_FORMAT(product.start_price) : ''}</span>
                        {
                            (this.state.firstRegularPrice != "$0.00 USD") ?
                                <span className="font_12 mb_14 d-block items_text fw_ar_reg line_through">{this.state.firstRegularPrice}</span>

                                : <span className="font_12 mb_14 d-block items_text fw_ar_reg"></span>
                        }
                        <NavLink className="shop_more add_cart_btn font_16 banner_text fw_ar_reg" to={`/product/${product.slug}`} >View Product</NavLink>
                    </div>
                </NavLink>
            </Fragment>
        );
    }
}

export default SingleProductListGrid;