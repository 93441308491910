import React, { Fragment, PureComponent } from 'react';
import Parser from 'html-react-parser';
import { NavLink } from 'react-router-dom';
import { AJAX_PUBLIC_REQUEST } from '../../Constants/AppConstants';
import SingleProductListGrid from './SingleProductListGrid';
import SingleProductModal from './SingleProductModal';
import SearchIcon from './../../Assets/images/customer_images/header_search.svg'
import HomeIcon from './../../Assets/images/refer_images/home_icon.svg'

class TopSellers extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            products: [],
            productDetails: {},
            filter_by: 'popular',
            totalProduct: 0,
            listView: false,
            products_filtered: [],
            searchName: '',
        }
        document.title = "Best Sellers - Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        this.getProducts(this.state.filter_by);
    }

    gridListView = (e) => {
        this.setState({ listView: !this.state.listView })
    }

    filterBy = (e) => {
        let filter_by = e.target.value;
        this.setState({
            loading: true,
            [e.target.name]: filter_by
        })
        this.getProducts(filter_by);
    }

    getProducts = (filterBy) => {
        const data = { filter_by: filterBy, type: 'single' }
        AJAX_PUBLIC_REQUEST("POST", "product/getList", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    products: results.response.data,
                    totalProduct: results.response.data.length,
                    products_filtered: results.response.data,
                    loading: false,
                });
            } else {
                this.setState({
                    error: Parser("<p className='text-danger'>" + results.response.message + "</p>"),
                    loading: false,
                })
            }
        });
    }

    quickView = (productId) => {
        this.setState({ productDetails: {} })
        const data = {
            product_id: productId,
        }
        AJAX_PUBLIC_REQUEST("POST", "product/getDetails", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    productDetails: results.response.data,
                });
            } else {
                this.setState({
                    error: Parser("<p className='text-danger'>" + results.response.message + "</p>"),
                })
            }
        });
    }

    //filter
    filterProduct = (e) => {
        this.setState({
            searchName: e.target.value,
            products_filtered: this.state.products.filter(function (product) {
                if (e.target.value === '') {
                    return product;
                } else {
                    let string = product.title.toUpperCase();
                    let substring = e.target.value.toUpperCase();
                    if (string.includes(substring)) {
                        return product;
                    }
                }
            }.bind(this))
        });
    }

    render() {

        const quickView = this.quickView;

        return (
            <Fragment>
                {
                    (this.state.loading) ?
                        <div className="loading container full_page_loader"></div>
                        :
                        <Fragment>
                            <div className="suplements_blk">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <main className="athletes_list_wrapper">


                                                <div className="d-flex align-items-center mb_28 justify-content-between">
                                                    <div className="pro_head_blk">
                                                        <h2 className="product_itle text_black let_spa_2 font_24 fw_el_semi mb_6">Best Sellers</h2>
                                                        <nav aria-label="breadcrumb">
                                                            <ol className="breadcrumb cus_breadcrumb pro_breadcrumb">
                                                                <li className="breadcrumb-item">
                                                                    <NavLink to="/"><img src={HomeIcon} alt="home_icon" /></NavLink>
                                                                </li>
                                                                <li className="breadcrumb-item active font_16 text_gray fw_ar_reg" aria-current="page">
                                                                    ~ Best Sellers
                                                                </li>
                                                            </ol>
                                                        </nav>
                                                    </div>
                                                    <div className="input_bundle_btn d-flex justify-content-end align-items-center">
                                                    <form className="product-search-form product_search_input nxt_bundle_btn position-relative form_input_blk" method="get">
                                                        <div className="search-input-group">
                                                            <input onChange={this.filterProduct} value={this.state.searchName}
                                                                className="form-control" placeholder="Search" name="searchName" id="filter-by" type="text" />
                                                        </div>
                                                        <div className="search_icon position-absolute d-flex align-items-center justify-content-center">
                                                            <img src={SearchIcon} alt="search icon" />
                                                        </div>
                                                    </form>
                                                      <NavLink className="build_bundle add_cart_btn font_16 d-block text-center" to={"/create-custom-bundle"} >Build Your Own Bundle</NavLink>
                                                    </div>
                                                    
                                                </div>

                                                <div className="row sup_pro_row">
                                                    {
                                                        (this.state.products_filtered.length <= 0) ? <h3>No Product Matched!</h3> :
                                                            this.state.products_filtered.map(function (product, key) {
                                                                // Added product quickviewer
                                                                product.quickView = this.quickView
                                                                return (
                                                                    (this.state.topSellerShow <= key) ? ''
                                                                        :
                                                                        <div key={Math.random()} className={this.state.listView ? 'col-xs-12 col-sm-4 col-md-4 col-lg-3 list_view' : 'col-xs-12 col-sm-4 col-md-4 col-lg-3'}>
                                                                            {/* <span className="product_text_overlay d-flex justify-content-center align-items-center">Best Seller</span> */}
                                                                            <SingleProductListGrid key={key} product={product} />
                                                                        </div>
                                                                )
                                                            }.bind(this))
                                                    }
                                                </div>
                                            </main>
                                        </div>
                                    </div>
                                    <SingleProductModal product={this.state.productDetails} />
                                </div>
                            </div>
                        </Fragment>
                }
            </Fragment>
        );
    }
}

export default TopSellers;
